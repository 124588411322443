import { all } from "redux-saga/effects";
import { AuthSaga } from "./auth/auth.saga";
import { productSaga } from "./products/products.saga";
import { cartSaga } from "./cart/cart.saga";
import { CategoryAddSaga } from "./category/category.saga";
import { UserSaga } from "./user/user.saga";
import { riseTockenSaga } from "./risetocken/rise.saga";
import { accSaga } from "./account/account.saga";

export default function* rootSaga() {
  yield all([
    AuthSaga(),
    productSaga(),
    cartSaga(),
    CategoryAddSaga(),
    UserSaga(),
    riseTockenSaga(),
    accSaga(),
  ]);
}
