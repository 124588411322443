import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import noImg from "../../assets/imgs/no-image.jpg";
import { API_BASE } from "../../services/config";

const ImageSlider = ({ images, id ,heightStyle}) => {
  let size=heightStyle? {height: heightStyle}:{}
console.log(id,'qwerhj');
  if (!images || images.length === 0) {
    return (
      <div
        id="carouselExampleIndicators"
        className="carousel slide"
        data-bs-ride="carousel"
      >
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img
              src={noImg}
              className="d-block w-100"
              alt="No Image Available"
              style={size}
            />
          </div>
        </div>
      </div>
    );
  }


  return (
    <div
      id={`carouselExampleIndicators-${id}`}
      className="carousel slide"
      data-bs-ride="carousel"
    >
      {/* Indicators */}
      <div className="carousel-indicators">
        {images.map((_, index) => (
          <button
            key={index}
            type="button"
            data-bs-target={`#carouselExampleIndicators-${id}`}
            data-bs-slide-to={index}
            className={index === 0 ? "active" : ""}
            aria-current={index === 0 ? "true" : undefined}
            aria-label={`Slide ${index + 1}`}
          ></button>
        ))}
      </div>

      {/* Carousel Items */}
      <div className="carousel-inner">
        {images.map((image, index) => (
          <div
            key={index}
            className={`carousel-item ${index === 0 ? "active" : ""}`}
          >
            {console.log(API_BASE + image, "kljsgljs")}
            <img
              src={API_BASE + image}
              className="d-block w-100"
              alt={`Slide ${index + 1}`}
              style={size}
            />
          </div>
        ))}
      </div>

      {/* Controls */}
      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target={`#carouselExampleIndicators-${id}`}
        data-bs-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next"
        type="button"
        data-bs-target={`#carouselExampleIndicators-${id}`}
        data-bs-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  );
};

export default ImageSlider;
