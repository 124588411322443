export const ProductActionTypes = {
  PRODUCTS_GET_REQUEST: "@product/PRODUCTS_GET_REQUEST",
  PRODUCTS_GET_SUCCESS: "@product/PRODUCTS_GET_SUCCESS",
  PRODUCTS_GET_FAILURE: "@product/PRODUCTS_GET_FAILURE",

  PRODUCTCATEGORY_GET_REQUEST: "@product/PRODUCTCATEGORY_GET_REQUEST",
  PRODUCTCATEGORY_GET_SUCCESS: "@product/PRODUCTCATEGORY_GET_SUCCESS",
  PRODUCTCATEGORY_GET_FAILURE: "@product/PRODUCTCATEGORY_GET_FAILURE",

  GETBRANDS_GET_REQUEST: "@product/GETBRANDS_GET_REQUEST",
  GETBRANDS_GET_SUCCESS: "@product/GETBRANDS_GET_SUCCESS",
  GETBRANDS_GET_FAILURE: "@product/GETBRANDS_GET_FAILURE",

  MAILPRODUCTDETIALS_GET_REQUEST: "@product/MAILPRODUCTDETIALS_GET_REQUEST",
  MAILPRODUCTDETIALS_GET_SUCCESS: "@product/MAILPRODUCTDETIALS_GET_SUCCESS",
  MAILPRODUCTDETIALS_GET_FAILURE: "@product/MAILPRODUCTDETIALS_GET_FAILURE",

  FILTERPRODUCTS_GET_REQUEST: "@product/FILTERPRODUCTS_GET_REQUEST",
  FILTERPRODUCTS_GET_SUCCESS: "@product/FILTERPRODUCTS_GET_SUCCESS",
  FILTERPRODUCTS_GET_FAILURE: "@product/FILTERPRODUCTS_GET_FAILURE",

  STATE_RESET_REQUEST: "@@product/STATE_RESET_REQUEST",
};

export const productInitialState = {
  loading: false,
  getProducts: [],
  categoryLoad: false,
  getCategoryList: [],
  getBrandLoad: false,
  getBrandList: [],
  sendMailLoad: false,
  mailSendSuccess: false,
  filterLoad:false,
  filterProductData:[]
};
export const productSelector = (state) => state.productReducer;
