import React from "react";
import { Route, Navigate, Outlet } from "react-router-dom";
import { connect } from "react-redux";

import { Container, Row, Col } from "react-bootstrap";
// import Nav from "../layout/nav/Nav";
console.log("inasdsad");
const AppRoute = () => {
  const isAuth = localStorage.getItem("isAuthenticated");
  const isAuthenticated = 1;
  console.log(isAuth, isAuthenticated, "ASDFASDGFASD");
  if (!isAuthenticated) {
    localStorage.clear();
  }
  return isAuth ? (
    <>
      {/* <Nav /> */}
      <div id="layout-wrapper">
        <div className="main-content">
          <Outlet />
        </div>
      </div>
    </>
  ) : (
    <Navigate to="/" />
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(AppRoute);
