import { Http } from "../../services/Http";
import { getBaseEndpointUrl } from "../../services/config";

export const userRegister = (postData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(baseUrl + "/user/register_by_number", postData, {});
};

export const userLogin = (payload) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(baseUrl + "/user/login_by_otp", payload);
};
export const updateUserApi = (postData) => {
  console.log(postData, "678");
  const baseUrl = getBaseEndpointUrl();
  return Http.put(
    baseUrl + "/user/" + postData.id,
    postData.formData,
    Http.getAuthToken()
  );
};
export const getUserDataApi = (payload) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(
    baseUrl + "/user?userId="+payload.userId,
    Http.getAuthToken()
  );
};

export const contatUsApi = formData => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(baseUrl + "/auth/contact_us", formData)
}