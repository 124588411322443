/* Enternal Imports */
import { Col, Container, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
/* Internal Imports */
import Footer from "../Footer";
import Header from "../Header";
import Sidebar from "../Sidebar";
import star from "../../assets/imgs/template/icons/star.svg";
import stargray from "../../assets/imgs/template/icons/star-gray.svg";
import imgsub from "../../assets/imgs/page/product/img-sub.png";
import imgsub2 from "../../assets/imgs/page/product/img-sub2.png";
import imgsub3 from "../../assets/imgs/page/product/img-sub3.png";
import imgsp3 from "../../assets/imgs/page/homepage1/imgsp3.png";
import imgsp1 from "../../assets/imgs/page/homepage1/imgsp1.png";
import imgsp2 from "../../assets/imgs/page/homepage1/imgsp2.png";
import imgsp4 from "../../assets/imgs/page/homepage1/imgsp4.png";
import imgsp5 from "../../assets/imgs/page/homepage1/imgsp5.png";
import imgsp6 from "../../assets/imgs/page/homepage1/imgsp6.png";
import imgsp7 from "../../assets/imgs/page/homepage1/imgsp7.png";
import delivery from "../../assets/imgs/template/delivery.svg";
import support from "../../assets/imgs/template/support.svg";
import voucher from "../../assets/imgs/template/voucher.svg";
import returned from "../../assets/imgs/template/return.svg";
import secure from "../../assets/imgs/template/secure.svg";
import gallery1 from "../../assets/imgs/page/product/img-gallery-1.jpg";
import gallery2 from "../../assets/imgs/page/product/img-gallery-2.jpg";
import gallery3 from "../../assets/imgs/page/product/img-gallery-3.jpg";
import gallery4 from "../../assets/imgs/page/product/img-gallery-4.jpg";
import gallery5 from "../../assets/imgs/page/product/img-gallery-5.jpg";
import gallery6 from "../../assets/imgs/page/product/img-gallery-6.jpg";
import gallery7 from "../../assets/imgs/page/product/img-gallery-7.jpg";

import {
  cartDestroyRequest,
  cartGetRequest,
  cartRemoveRequest,
  cartStateReset,
  checkOutDataRequest,
  displayRazorPayRequest,
  resetInitialstate,
} from "../../store/cart/cart.action";
import { cartSelector } from "../../store/cart/cart.model";
import { API_BASE } from "../../services/config";
import { PAY_METHOD, TaxPercentage } from "../../utils/Constants";
import noImg from "../../assets/imgs/no-image.jpg";
import { userSelector } from "../../store/user/user.model";
import { getUserDataRequest } from "../../store/user/user.action";
import ImageSlider from "../Slider/ImageSlider";

function Shopcart() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const id = localStorage.getItem("id");
  const isType = localStorage.getItem("type");
  const isAuthenticated = localStorage.getItem("isAuthenticated");

  const { getCarts, succesMessage, paymentSuccess ,Purchasesuccess} = useSelector(cartSelector);
  const { userData } = useSelector(userSelector);
  const MySwal = withReactContent(Swal);
  console.log(paymentSuccess, "utadf");
  console.log(getCarts, "fhghi6");
  /* State */
  const [cartItems, setCartItems] = useState(  []);
  const [totalPrice, setTotalPrice] = useState(0);
  const [subtotalPrice, setSubTotalPrice] = useState(0);
  const [totalTax, setTotalTax] = useState(0);
  const [tax_amount, setTax] = useState();
  const [payMethod, setPayMethod] = useState({
    cod: true,
    online: false,
    cotation:false
  });
  const [isProcessing, setIsProcessing] = useState(false);
  console.log(payMethod, "paihja");
  /* Functions */
  console.log(cartItems?.total_sale_price, "ll");

  const handleQuantityChange = (index, newQuantity) => {
    const updatedItems = cartItems.map((ele, idx) => {
      if (idx === index) {
        const updatedQuantity = Number(newQuantity);
        const salePrice = Number(
          isType == 0 ? ele?.msrp : isType == 1 ? ele?.w_rate : ele?.c_rate
        );

        return {
          ...ele,
          quantity: updatedQuantity,
          // total_sale_price: updatedQuantity * salePrice,
        };
      }
      return ele;
    });

    setCartItems(updatedItems);
  };

  const handleRemoveItem = (index) => {
    const updatedItems = cartItems.filter((_, idx) => idx !== index);
    setCartItems(updatedItems);
    console.log(index,'dfkjsh');
    const payload={
      id:id
    }
    dispatch(cartRemoveRequest(payload))
  };

  const handlePayCOD = (e) => {
    setPayMethod({
      cod: true,
      online: false,
      cotation:false
    });
  };
  const handlePayOnline = (e) => {
    setPayMethod({
      cod: false,
      online: true,
      cotation:false,
    });
  };
  const handleCotation = (e) => {
    setPayMethod({
      cod: false,
      online: false,
      cotation:true,
    });
  };
  const handleCheckOut = () => {
  
    const data=cartItems.map((ele)=>{
      const wotax= isType == 0
      ? ele?.product?.msrp
      : isType == 1
      ? ele?.product?.w_rate
      : ele?.product?.c_rate
      const rate= isType == 0
      ? (
          Number(ele?.product?.msrp) +
          Number(
            TaxPercentage.find((t) => {
              if (ele?.product?.tax == t.value) {
                return t?.percent;
              }
            })?.percent / 100
          ) *
            ele?.product?.msrp
        ).toFixed(2)
      : isType == 1
      ? (
          Number(ele?.product?.w_rate) +
          Number(
            TaxPercentage.find((t) => {
              if (ele?.product?.tax == t.value) {
                return t?.percent;
              }
            })?.percent / 100
          ) *
            ele?.product?.w_rate
        ).toFixed(2)
      : (
          Number(ele?.product?.c_rate) +
          Number(
            TaxPercentage.find((t) => {
              if (ele?.product?.tax == t.value) {
                return t?.percent;
              }
            })?.percent / 100
          ) *
            ele?.product?.c_rate
        ).toFixed(2)
        const taxPercentage = (() => {
          switch (ele.product.tax) {
            case "GST 5%":
              return 5;
            case "GST 12%":
              return 12;
            case "GST 18%":
              return 18;
            case "GST 28%":
              return 28;
            default:
              return 0;
          }
        })();
        const texs=TaxPercentage.find((t)=>t.value ==ele.product.tax)
        return {
          user_id: ele?.user_id,
          product_id: ele.product_id,
          category_id: ele.category_id,
          quantity: ele.quantity,
          reg_price: ele?.reg_price,
          // sale_price: ele.sale_price,
          amount: ele.amount,
          // tax:taxamt,
          tax_per:texs.percent,
          
          sale_price:ele.sale_price,
          tax:rate-wotax
        }
    })
    
    // console.log(data,'hgfhfhfsd');
    // console.log("ghfkghkf",cartItems,'hgfhfhfsd');
    let datas = {
      total_price: totalPrice,
      sub_total: subtotalPrice,
      tax_price: totalTax,
      // cartDetails: {
      //   id: cartItems?.map((items) => {
      //     return items?.id;
      //   }),
      // },
      cartDetails:[{data}]
    };
    // if (payMethod.online) {
    //   datas.type = PAY_METHOD.ONLINE;
    //   dispatch(displayRazorPayRequest({ datas, userData }));
    // }
    console.log(datas,'hgfhfhfsd');
// return 
    MySwal.fire({
      title: "Would You Like To Confirm Your Order??",
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: "Ok",
      cancelButtonText: "Cancel",
    }).then((result) => {
    
      if (result.isConfirmed) {
        if (payMethod.cod) {
          datas.type = PAY_METHOD.COD;
          dispatch(checkOutDataRequest({ datas }));
          dispatch(cartDestroyRequest(id));
        } else if (payMethod.online) {
          datas.type = PAY_METHOD.ONLINE;
          console.log({ datas, userData }, "dadf12stas");
          dispatch(displayRazorPayRequest({ datas, userData }));
          dispatch(cartDestroyRequest(id));
        } else if(payMethod.cotation){
         
          
          datas.type = PAY_METHOD.COTATION;
          console.log("cotation",datas);
          dispatch(checkOutDataRequest({ datas }));
          dispatch(cartDestroyRequest(id));
        }
      }
    });
  };
  /* UseEffect */
  useEffect(()=>{
    setCartItems(getCarts)
  },[getCarts])
  useEffect(() => {
    // let taxVal = settingDatas?.tax ? settingDatas?.tax : 18;
    // let taxAmts = ((cartProducttotal / 100) * parseFloat(taxVal)).toFixed();
    // setTax(taxAmts);
    console.log(cartItems[0]?.total_sale_price, "ll");
    const totalamt = cartItems.reduce(
      (acc, item) =>
        acc +
        Number(
          item.quantity *
            parseFloat(
              isType == 0
                ? item?.product?.msrp
                : isType == 1
                ? item?.product?.w_rate
                : item?.product?.c_rate
            ).toFixed(2)
        ),
        0
    );
    
    const total = cartItems.reduce(
      (acc, item) =>
        Math.floor(
          acc +
            Number(
              item.quantity *
                parseFloat(
                  isType == 0
                    ? (
                        Number(item?.product?.msrp) +
                        Number(
                          TaxPercentage.find((t) => {
                            if (item?.product?.tax == t.value) {
                              return t?.percent;
                            }
                          })?.percent / 100
                        ) *
                          item?.product?.msrp
                      ).toFixed(2)
                    : isType == 1
                    ? (
                        Number(item?.product?.w_rate) +
                        Number(
                          TaxPercentage.find((t) => {
                            if (item?.product?.tax == t.value) {
                              return t?.percent;
                            }
                          })?.percent / 100
                        ) *
                          item?.product?.w_rate
                      ).toFixed(2)
                    : (
                        Number(item?.product?.c_rate) +
                        Number(
                          TaxPercentage.find((t) => {
                            if (item?.product?.tax == t.value) {
                              return t?.percent;
                            }
                          })?.percent / 100
                        ) *
                          item?.product?.c_rate
                      ).toFixed(2)
                ).toFixed(2)
            )
        ),
      0
    );
    const tax=total-totalamt;
    console.log(totalamt,tax,'ghiduad');
    setTotalTax(tax)
    setSubTotalPrice(totalamt)
    setTotalPrice(total);
  }, [cartItems]);

  // useEffect(() => {
  //   if(isAuthenticated){
  //     const payload = {
  //       page: 0,
  //       limit: 0,
  //       query: "",
  //     };
  //     dispatch(cartGetRequest(payload));
  //     const payload1 = {
  //       userId: id,
  //     };
  //     dispatch(getUserDataRequest(payload1));
  //   }
  //   else{
  //     navigate('/login')
  //   }
    
   
  // }, [isAuthenticated]);
  useEffect(() => {
    if (succesMessage == "Payment Created" || paymentSuccess == "Success"||succesMessage == "Cotation Created") {
      toast.dismiss();
      toast.success("OrderPlaced SuccessFully");
      dispatch(resetInitialstate());
      navigate("/orders");
    }
  }, [succesMessage, paymentSuccess]);

  return (
    <>
      <Header />
      <Sidebar />
      <div className="section-box">
        <div className="breadcrumbs-div">
          <Container>
            <ul className="breadcrumb">
              <li>
                <a className="font-xs color-gray-1000">Home</a>
              </li>
              <li>
                <a className="font-xs color-gray-500">Shop</a>
              </li>
              <li>
                <a className="font-xs color-gray-500">Cart</a>
              </li>
            </ul>
          </Container>
        </div>
      </div>
      <section className="section-box shop-template">
        <Container>
          <Row>
            <Col lg={9}>
              <div className="box-carts">
                <div className="head-wishlist">
                  <div className="item-wishlist">
                    {/* <div className="wishlist-cb">
                      <input className="cb-layout cb-all" type="checkbox" />
                    </div> */}
                    <div className="wishlist-product">
                      <span className="font-md-bold color-brand-3">
                        Product
                      </span>
                    </div>
                    <div className="wishlist-price">
                      <span className="font-md-bold color-brand-3">
                        Unit Price
                      </span>
                    </div>
                    <div className="wishlist-status">
                      <span className="font-md-bold color-brand-3">
                        Quantity
                      </span>
                    </div>
                    <div className="wishlist-action">
                      <span className="font-md-bold color-brand-3">
                        Subtotal
                      </span>
                    </div>
                    <div className="wishlist-remove">
                      <span className="font-md-bold color-brand-3">Remove</span>
                    </div>
                  </div>
                </div>
                      {/* <button onClick={()=>dispatch(cartDestroyRequest(id))}>Delete All</button> */}
                {cartItems?.length > 0 ? (
                  cartItems?.map((ele, index) => {
                    return (
                      <div className="content-wishlist mb-20">
                        <div className="item-wishlist">
                          {/* <div className="wishlist-cb">
                          <input
                            className="cb-layout cb-select"
                            type="checkbox"
                          />
                        </div> */}
                          <div className="wishlist-product">
                            <div className="product-wishlist">
                              <div className="product-image">
                                <a href="shop-single-product.html">
                                  {/* <img
                                    src={
                                      ele?.product?.image
                                        ? API_BASE +
                                          "products/" +
                                          ele?.product?.image
                                        : noImg
                                    }
                                    alt="Ecom"
                                  /> */}
                                  <ImageSlider images={ ele?.product?.image} id={index} heightStyle={"100px"}/>
                                </a>
                              </div>
                              <div className="product-info">
                                <a href="shop-single-product.html">
                                  <h6 className="color-brand-3">
                                    {ele?.product?.name}
                                  </h6>
                                </a>
                                <div className="rating">
                                  <img src={star} alt="Ecom" />
                                  <img src={star} alt="Ecom" />
                                  <img src={star} alt="Ecom" />
                                  <img src={star} alt="Ecom" />
                                  <img src={star} alt="Ecom" />
                                  <span className="font-xs color-gray-500">
                                    {" "}
                                    (65)
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="wishlist-price">
                            <h4 className="color-brand-3">
                              ₹
                              {parseFloat(
                                isType == 0
                                  ? (
                                      Number(ele?.product?.msrp) +
                                      Number(
                                        TaxPercentage.find((t) => {
                                          if (ele?.product?.tax == t.value) {
                                            return t?.percent;
                                          }
                                        })?.percent / 100
                                      ) *
                                        ele?.product?.msrp
                                    ).toFixed(2)
                                  : isType == 1
                                  ? (
                                      Number(ele?.product?.w_rate) +
                                      Number(
                                        TaxPercentage.find((t) => {
                                          if (ele?.product?.tax == t?.value) {
                                            return t?.percent;
                                          }
                                        })?.percent / 100
                                      ) *
                                        ele?.product?.w_rate
                                    ).toFixed(2)
                                  : (
                                      Number(ele?.product?.c_rate) +
                                      Number(
                                        TaxPercentage.find((t) => {
                                          if (ele?.product?.tax == t?.value) {
                                            return t?.percent;
                                          }
                                        })?.percent / 100
                                      ) *
                                        ele?.product?.c_rate
                                    ).toFixed(2)
                              )}
                              {/* {parseFloat(
                                isType == 0
                                  ? ele?.product?.msrp
                                  : isType == 1
                                  ? ele?.product?.w_rate
                                  : ele?.product?.c_rate
                              ).toFixed(2)} */}
                            </h4>
                          </div>
                          <div className="wishlist-status">
                            <div className="box-quantity">
                              <div className="input-quantity">
                                <input
                                  className="font-xl color-brand-3"
                                  type="text"
                                  defaultValue={ele?.quantity}
                                  onChange={(e) =>
                                    handleQuantityChange(index, e.target.value)
                                  }
                                />
                                {/* <span className="minus-cart"></span>
                                <span className="plus-cart"></span> */}
                              </div>
                            </div>
                          </div>
                          <div className="wishlist-action">
                            <h4 className="color-brand-3">
                              {parseFloat(
                                ele.quantity *
                                  (isType == 0
                                    ? (
                                        Number(ele?.product?.msrp) +
                                        Number(
                                          TaxPercentage.find((t) => {
                                            if (ele?.product?.tax == t.value) {
                                              return t?.percent;
                                            }
                                          })?.percent / 100
                                        ) *
                                          ele?.product?.msrp
                                      ).toFixed(2)
                                    : isType == 1
                                    ? (
                                        Number(ele?.product?.w_rate) +
                                        Number(
                                          TaxPercentage.find((t) => {
                                            if (ele?.product?.tax == t.value) {
                                              return t?.percent;
                                            }
                                          })?.percent / 100
                                        ) *
                                          ele?.product?.w_rate
                                      ).toFixed(2)
                                    : (
                                        Number(ele?.product?.c_rate) +
                                        Number(
                                          TaxPercentage.find((t) => {
                                            if (ele?.product?.tax == t?.value) {
                                              return t?.percent;
                                            }
                                          })?.percent / 100
                                        ) *
                                          ele?.product?.c_rate
                                      ).toFixed(2))
                              ).toFixed(2)}
                            </h4>
                          </div>
                          <div className="wishlist-remove">
                            <button
                              onClick={() => handleRemoveItem(index)}
                              className="btn btn-delete"
                              href="#"
                            ></button>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="text-center mb-75 mt-50">
                    <h4>Your cart is currently empty. Start adding items!</h4>
                  </div>
                )}

                <div className="row mb-40">
                  <div className="col-lg-6 col-md-6 col-sm-6-col-6">
                    <Link
                      to="/purchase"
                      className="btn btn-buy w-auto arrow-back mb-10"
                    >
                      Continue shopping
                    </Link>
                  </div>
                  {/* <div className="col-lg-6 col-md-6 col-sm-6-col-6 text-md-end">
                    <a
                      className="btn btn-buy w-auto update-cart mb-10"
                      href="shop-cart.html"
                    >
                      Update cart
                    </a>
                  </div> */}
                </div>
                {/* <div className="row mb-50">
                  <div className="col-lg-6 col-md-6">
                    <div className="box-cart-left">
                      <h5 className="font-md-bold mb-10">Calculate Shipping</h5><span className="font-sm-bold mb-5 d-inline-block color-gray-500">Flat rate:</span><span className="font-sm-bold d-inline-block color-brand-3">5%</span>
                      <div className="form-group">
                        <select className="form-control select-style1 color-gray-700">
                          <option value="1">USA</option>
                          <option value="1">EURO</option>
                        </select>
                      </div>
                      <div className="row">
                        <div className="col-lg-6 mb-10">
                          <input className="form-control" placeholder="Stage / Country"/>
                        </div>
                        <div className="col-lg-6 mb-10">
                          <input className="form-control" placeholder="PostCode / ZIP"/>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="box-cart-right p-20">
                      <h5 className="font-md-bold mb-10">Apply Coupon</h5><span className="font-sm-bold mb-5 d-inline-block color-gray-500">Using A Promo Code?</span>
                      <div className="form-group d-flex">
                        <input className="form-control mr-15" placeholder="Enter Your Coupon"/>
                        <button className="btn btn-buy w-auto">Apply</button>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </Col>
            <Col lg={3}>
              <div className="summary-cart">
                {/* <div className="border-bottom mb-10">
                  <div className="row">
                    <div className="col-6">
                      <span className="font-md-bold color-gray-500">
                        Subtotal
                      </span>
                    </div>
                    <div className="col-6 text-end">
                      <h4>{totalPrice}</h4>
                    </div>
                  </div>
                </div> */}
                {/* <div className="border-bottom mb-10">
                  <div className="row">
                    <div className="col-6">
                      <span className="font-md-bold color-gray-500">
                        Shipping
                      </span>
                    </div>
                    <div className="col-6 text-end">
                      <h4> Free</h4>
                    </div>
                  </div>
                </div>
                <div className="border-bottom mb-10">
                  <div className="row">
                    <div className="col-6">
                      <span className="font-md-bold color-gray-500">
                        Estimate for
                      </span>
                    </div>
                    <div className="col-6 text-end">
                      <h6>India</h6>
                    </div>
                  </div>
                </div> */}
                <div className="mb-20">
                <div className="row mt-10">
                    <div className="col-6">
                      <span className="font-md color-gray-500">Sub Total</span>
                      <br></br>
                    </div>
                    <div className="col-6 text-end ">
                      <h5> {parseFloat(subtotalPrice).toFixed(2)}</h5>
                    </div>
                    {/* <small>Excluding Transportation charges</small> */}
                  </div>
                  <hr />
                  <div className="row mt-10">
                    <div className="col-6">
                      <span className="font-md color-gray-500">Tax</span>
                      <br></br>
                    </div>
                    <div className="col-6 text-end ">
                      <h5> {parseFloat(totalTax).toFixed(2)}</h5>
                    </div>
                    {/* <small>Excluding Transportation charges</small> */}
                  </div>
                  <hr />
                  <div className="row mt-10">
                    <div className="col-6">
                      <span className="font-md-bold color-gray-500">Total</span>
                      <br></br>
                    </div>
                    <div className="col-6 text-end ">
                      <h4> {parseFloat(totalPrice).toFixed(2)}</h4>
                    </div>
                    {/* <small>Excluding Transportation charges</small> */}
                  </div>
                  <hr />
                </div>
                <div>
                <label className="cb-container">
                    <input
                      type="radio"
                      name="pay_type"
                      checked={payMethod.cotation}
                      onClick={handleCotation}
                    />
                    <span className="text-small">Cotation</span>
                    <span className="checkmark"></span>
                  </label>
                  <label className="cb-container">
                    <input
                      type="radio"
                      name="pay_type"
                      checked={payMethod.cod}
                      onClick={handlePayCOD}
                    />
                    <span className="text-small">Credit</span>
                    <span className="checkmark"></span>
                  </label>
                  <label className="cb-container">
                    <input
                      type="radio"
                      name="pay_type"
                      checked={payMethod.online}
                      onClick={handlePayOnline}
                    />
                    <span className="text-small">Payment Gateway</span>
                    <span className="checkmark"></span>
                  </label>
                </div>
                {/* <div className="wishlist-cb">
                  <input className="cb-layout cb-select" type="checkbox" />
                  <label className="cb-container">Credit</label>
                </div>
                <div className="wishlist-cb">
                  <input className="cb-layout cb-select" type="checkbox" />
                  <label className="cb-container">Payment Gateway</label>
                </div> */}
                <div className="box-button">
                  <button onClick={handleCheckOut} className="btn btn-buy">
                    Place Order
                  </button>
                </div>
              </div>
            </Col>
          </Row>
          {/* <h4 className="color-brand-3">You may also like</h4>
          <div className="list-products-5 mt-20 mb-40">
            <div className="card-grid-style-3">
              <div className="card-grid-inner">
                <div className="tools"><a className="btn btn-trend btn-tooltip mb-10" href="#" aria-label="Trend" data-bs-placement="left"></a><a className="btn btn-wishlist btn-tooltip mb-10" href="shop-wishlist.html" aria-label="Add To Wishlist"></a><a className="btn btn-compare btn-tooltip mb-10" href="shop-compare.html" aria-label="Compare"></a><a className="btn btn-quickview btn-tooltip" aria-label="Quick view" href="#ModalQuickview" data-bs-toggle="modal"></a></div>
                <div className="image-box"><span className="label bg-brand-2">-17%</span><a href="shop-single-product.html"><img src={imgsp3} alt="Ecom"/></a></div>
                <div className="info-right"><a className="font-xs color-gray-500" href="shop-vendor-single.html">Hisense</a><br/><a className="color-brand-3 font-sm-bold" href="shop-single-product.html">Hisense 43&quot; Class 4K UHD LED XclassName Smart TV HDR</a>
                  <div className="rating"><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><span className="font-xs color-gray-500">(65)</span></div>
                  <div className="price-info"><strong className="font-lg-bold color-brand-3 price-main">$2856.3</strong><span className="color-gray-500 price-line">$3225.6</span></div>
                  <div className="mt-20 box-btn-cart"><a className="btn btn-cart" href="shop-cart.html">Add To Cart</a></div>
                  <ul className="list-features">
                    <li>27-inch (diagonal) Retina 5K display</li>
                    <li>3.1GHz 6-core 10th-generation Intel Core i5</li>
                    <li>AMD Radeon Pro 5300 graphics</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="card-grid-style-3">
              <div className="card-grid-inner">
                <div className="tools"><a className="btn btn-trend btn-tooltip mb-10" href="#" aria-label="Trend" data-bs-placement="left"></a><a className="btn btn-wishlist btn-tooltip mb-10" href="shop-wishlist.html" aria-label="Add To Wishlist"></a><a className="btn btn-compare btn-tooltip mb-10" href="shop-compare.html" aria-label="Compare"></a><a className="btn btn-quickview btn-tooltip" aria-label="Quick view" href="#ModalQuickview" data-bs-toggle="modal"></a></div>
                <div className="image-box"><span className="label bg-brand-2">-17%</span><a href="shop-single-product.html"><img src={imgsp4} alt="Ecom"/></a></div>
                <div className="info-right"><a className="font-xs color-gray-500" href="shop-vendor-single.html">Apple</a><br/><a className="color-brand-3 font-sm-bold" href="shop-single-product.html">2022 Apple 10.9-inch iPad Air Wi-Fi 64GB - Silver</a>
                  <div className="rating"><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><span className="font-xs color-gray-500">(65)</span></div>
                  <div className="price-info"><strong className="font-lg-bold color-brand-3 price-main">$2856.3</strong><span className="color-gray-500 price-line">$3225.6</span></div>
                  <div className="mt-20 box-btn-cart"><a className="btn btn-cart" href="shop-cart.html">Add To Cart</a></div>
                  <ul className="list-features">
                    <li>27-inch (diagonal) Retina 5K display</li>
                    <li>3.1GHz 6-core 10th-generation Intel Core i5</li>
                    <li>AMD Radeon Pro 5300 graphics</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="card-grid-style-3">
              <div className="card-grid-inner">
                <div className="tools"><a className="btn btn-trend btn-tooltip mb-10" href="#" aria-label="Trend" data-bs-placement="left"></a><a className="btn btn-wishlist btn-tooltip mb-10" href="shop-wishlist.html" aria-label="Add To Wishlist"></a><a className="btn btn-compare btn-tooltip mb-10" href="shop-compare.html" aria-label="Compare"></a><a className="btn btn-quickview btn-tooltip" aria-label="Quick view" href="#ModalQuickview" data-bs-toggle="modal"></a></div>
                <div className="image-box"><span className="label bg-brand-2">-17%</span><a href="shop-single-product.html"><img src={imgsp5} alt="Ecom"/></a></div>
                <div className="info-right"><a className="font-xs color-gray-500" href="shop-vendor-single.html">LG</a><br/><a className="color-brand-3 font-sm-bold" href="shop-single-product.html">LG 65&quot; Class 4K UHD Smart TV OLED A1 Series </a>
                  <div className="rating"><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><span className="font-xs color-gray-500">(65)</span></div>
                  <div className="price-info"><strong className="font-lg-bold color-brand-3 price-main">$2856.3</strong><span className="color-gray-500 price-line">$3225.6</span></div>
                  <div className="mt-20 box-btn-cart"><a className="btn btn-cart" href="shop-cart.html">Add To Cart</a></div>
                  <ul className="list-features">
                    <li>27-inch (diagonal) Retina 5K display</li>
                    <li>3.1GHz 6-core 10th-generation Intel Core i5</li>
                    <li>AMD Radeon Pro 5300 graphics</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="card-grid-style-3">
              <div className="card-grid-inner">
                <div className="tools"><a className="btn btn-trend btn-tooltip mb-10" href="#" aria-label="Trend" data-bs-placement="left"></a><a className="btn btn-wishlist btn-tooltip mb-10" href="shop-wishlist.html" aria-label="Add To Wishlist"></a><a className="btn btn-compare btn-tooltip mb-10" href="shop-compare.html" aria-label="Compare"></a><a className="btn btn-quickview btn-tooltip" aria-label="Quick view" href="#ModalQuickview" data-bs-toggle="modal"></a></div>
                <div className="image-box"><span className="label bg-brand-2">-17%</span><a href="shop-single-product.html"><img src={imgsp6} alt="Ecom"/></a></div>
                <div className="info-right"><a className="font-xs color-gray-500" href="shop-vendor-single.html">Apple</a><br/><a className="color-brand-3 font-sm-bold" href="shop-single-product.html">Apple AirPods Pro with MagSafe Charging Case</a>
                  <div className="rating"><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><span className="font-xs color-gray-500">(65)</span></div>
                  <div className="price-info"><strong className="font-lg-bold color-brand-3 price-main">$2856.3</strong><span className="color-gray-500 price-line">$3225.6</span></div>
                  <div className="mt-20 box-btn-cart"><a className="btn btn-cart" href="shop-cart.html">Add To Cart</a></div>
                  <ul className="list-features">
                    <li>27-inch (diagonal) Retina 5K display</li>
                    <li>3.1GHz 6-core 10th-generation Intel Core i5</li>
                    <li>AMD Radeon Pro 5300 graphics</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="card-grid-style-3">
              <div className="card-grid-inner">
                <div className="tools"><a className="btn btn-trend btn-tooltip mb-10" href="#" aria-label="Trend" data-bs-placement="left"></a><a className="btn btn-wishlist btn-tooltip mb-10" href="shop-wishlist.html" aria-label="Add To Wishlist"></a><a className="btn btn-compare btn-tooltip mb-10" href="shop-compare.html" aria-label="Compare"></a><a className="btn btn-quickview btn-tooltip" aria-label="Quick view" href="#ModalQuickview" data-bs-toggle="modal"></a></div>
                <div className="image-box"><span className="label bg-brand-2">-17%</span><a href="shop-single-product.html"><img src={imgsp7} alt="Ecom"/></a></div>
                <div className="info-right"><a className="font-xs color-gray-500" href="shop-vendor-single.html">Razer</a><br/><a className="color-brand-3 font-sm-bold" href="shop-single-product.html">Razer Power Up Gaming Bundle V2 - Cynosa Lite</a>
                  <div className="rating"><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><span className="font-xs color-gray-500">(65)</span></div>
                  <div className="price-info"><strong className="font-lg-bold color-brand-3 price-main">$2856.3</strong><span className="color-gray-500 price-line">$3225.6</span></div>
                  <div className="mt-20 box-btn-cart"><a className="btn btn-cart" href="shop-cart.html">Add To Cart</a></div>
                  <ul className="list-features">
                    <li>27-inch (diagonal) Retina 5K display</li>
                    <li>3.1GHz 6-core 10th-generation Intel Core i5</li>
                    <li>AMD Radeon Pro 5300 graphics</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <h4 className="color-brand-3">Recently viewed items</h4>
          <div className="row mt-40">
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="card-grid-style-2 card-grid-none-border hover-up">
                <div className="image-box"><a href="shop-single-product.html"><img src={imgsp1} alt="Ecom"/></a>
                </div>
                <div className="info-right"><span className="font-xs color-gray-500">HP</span><br/><a className="color-brand-3 font-xs-bold" href="shop-single-product.html">HP DeskJet 2755e Wireless Color All-in-One Printer</a>
                  <div className="rating"><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><span className="font-xs color-gray-500"> (65)</span></div>
                  <div className="price-info"><strong className="font-lg-bold color-brand-3 price-main">$2556.3</strong><span className="color-gray-500 price-line">$3225.6</span></div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="card-grid-style-2 card-grid-none-border hover-up">
                <div className="image-box"><a href="shop-single-product.html"><img src={imgsp2} alt="Ecom"/></a>
                </div>
                <div className="info-right"><span className="font-xs color-gray-500">HP</span><br/><a className="color-brand-3 font-xs-bold" href="shop-single-product.html">Original HP 63XL Black High-yield Ink Cartridge</a>
                  <div className="rating"><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><span className="font-xs color-gray-500"> (65)</span></div>
                  <div className="price-info"><strong className="font-lg-bold color-brand-3 price-main">$2556.3</strong><span className="color-gray-500 price-line">$3225.6</span></div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="card-grid-style-2 card-grid-none-border hover-up">
                <div className="image-box"><a href="shop-single-product.html"><img src={imgsp1} alt="Ecom"/></a>
                </div>
                <div className="info-right"><span className="font-xs color-gray-500">Logitech</span><br/><a className="color-brand-3 font-xs-bold" href="shop-single-product.html">Logitech H390 Wired Headset, Stereo Headphones</a>
                  <div className="rating"><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><span className="font-xs color-gray-500"> (65)</span></div>
                  <div className="price-info"><strong className="font-lg-bold color-brand-3 price-main">$2556.3</strong><span className="color-gray-500 price-line">$3225.6</span></div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="card-grid-style-2 card-grid-none-border hover-up">
                <div className="image-box"><a href="shop-single-product.html"><img src={imgsp2} alt="Ecom"/></a>
                </div>
                <div className="info-right"><span className="font-xs color-gray-500">Logitech</span><br/><a className="color-brand-3 font-xs-bold" href="shop-single-product.html">Logitech MK345 Wireless Combo Full-Sized</a>
                  <div className="rating"><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><span className="font-xs color-gray-500"> (65)</span></div>
                  <div className="price-info"><strong className="font-lg-bold color-brand-3 price-main">$2556.3</strong><span className="color-gray-500 price-line">$3225.6</span></div>
                </div>
              </div>
            </div>
          </div> */}
        </Container>
      </section>
      <section className="section-box mt-90 mb-50">
        <Container>
          <ul className="list-col-5">
            <li>
              <div className="item-list">
                <div className="icon-left">
                  <img src={delivery} alt="Ecom" />
                </div>
                <div className="info-right">
                  <h5 className="font-lg-bold color-gray-100">Free Delivery</h5>
                  <p className="font-sm color-gray-500">
                    From all orders over $10
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div className="item-list">
                <div className="icon-left">
                  <img src={support} alt="Ecom" />
                </div>
                <div className="info-right">
                  <h5 className="font-lg-bold color-gray-100">Support 24/7</h5>
                  <p className="font-sm color-gray-500">Shop with an expert</p>
                </div>
              </div>
            </li>
            <li>
              <div className="item-list">
                <div className="icon-left">
                  <img src={voucher} alt="Ecom" />
                </div>
                <div className="info-right">
                  <h5 className="font-lg-bold color-gray-100">Gift voucher</h5>
                  <p className="font-sm color-gray-500">Refer a friend</p>
                </div>
              </div>
            </li>
            <li>
              <div className="item-list">
                <div className="icon-left">
                  <img src={returned} alt="Ecom" />
                </div>
                <div className="info-right">
                  <h5 className="font-lg-bold color-gray-100">
                    Return &amp; Refund
                  </h5>
                  <p className="font-sm color-gray-500">
                    Free return over $200
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div className="item-list">
                <div className="icon-left">
                  <img src={secure} alt="Ecom" />
                </div>
                <div className="info-right">
                  <h5 className="font-lg-bold color-gray-100">
                    Secure payment
                  </h5>
                  <p className="font-sm color-gray-500">100% Protected</p>
                </div>
              </div>
            </li>
          </ul>
        </Container>
      </section>
      <section className="section-box box-newsletter">
        <Container>
          <Row>
            <Col lg={6} md={7} sm={12}>
              <h3 className="color-white">
                Subscrible &amp; Get <span className="color-warning">10%</span>{" "}
                Discount
              </h3>
              <p className="font-lg color-white">
                Get E-mail updates about our latest shop and{" "}
                <span className="font-lg-bold">special offers.</span>
              </p>
            </Col>
            <Col lg={4} md={5} sm={12}>
              <div className="box-form-newsletter mt-15">
                <form className="form-newsletter">
                  <input
                    className="input-newsletter font-xs"
                    value=""
                    placeholder="Your email Address"
                  />
                  <button className="btn btn-brand-2">Sign Up</button>
                </form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <div
        className="modal fade"
        id="ModalFiltersForm"
        tabindex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content apply-job-form">
            <div className="modal-header">
              <h5 className="modal-title color-gray-1000 filters-icon">
                Addvance Fillters
              </h5>
              <button
                className="btn-close"
                type="button"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body p-30">
              <div className="row">
                <div className="col-w-1">
                  <h6 className="color-gray-900 mb-0">Brands</h6>
                  <ul className="list-checkbox">
                    <li>
                      <label className="cb-container">
                        <input type="checkbox" checked="checked" />
                        <span className="text-small">Apple</span>
                        <span className="checkmark"></span>
                      </label>
                    </li>
                    <li>
                      <label className="cb-container">
                        <input type="checkbox" />
                        <span className="text-small">Samsung</span>
                        <span className="checkmark"></span>
                      </label>
                    </li>
                    <li>
                      <label className="cb-container">
                        <input type="checkbox" />
                        <span className="text-small">Baseus</span>
                        <span className="checkmark"></span>
                      </label>
                    </li>
                    <li>
                      <label className="cb-container">
                        <input type="checkbox" />
                        <span className="text-small">Remax</span>
                        <span className="checkmark"></span>
                      </label>
                    </li>
                    <li>
                      <label className="cb-container">
                        <input type="checkbox" />
                        <span className="text-small">Handtown</span>
                        <span className="checkmark"></span>
                      </label>
                    </li>
                    <li>
                      <label className="cb-container">
                        <input type="checkbox" />
                        <span className="text-small">Elecom</span>
                        <span className="checkmark"></span>
                      </label>
                    </li>
                    <li>
                      <label className="cb-container">
                        <input type="checkbox" />
                        <span className="text-small">Razer</span>
                        <span className="checkmark"></span>
                      </label>
                    </li>
                    <li>
                      <label className="cb-container">
                        <input type="checkbox" />
                        <span className="text-small">Auto Focus</span>
                        <span className="checkmark"></span>
                      </label>
                    </li>
                    <li>
                      <label className="cb-container">
                        <input type="checkbox" />
                        <span className="text-small">Nillkin</span>
                        <span className="checkmark"></span>
                      </label>
                    </li>
                    <li>
                      <label className="cb-container">
                        <input type="checkbox" />
                        <span className="text-small">Logitech</span>
                        <span className="checkmark"></span>
                      </label>
                    </li>
                    <li>
                      <label className="cb-container">
                        <input type="checkbox" />
                        <span className="text-small">ChromeBook</span>
                        <span className="checkmark"></span>
                      </label>
                    </li>
                  </ul>
                </div>
                <div className="col-w-1">
                  <h6 className="color-gray-900 mb-0">Special offers</h6>
                  <ul className="list-checkbox">
                    <li>
                      <label className="cb-container">
                        <input type="checkbox" />
                        <span className="text-small">On sale</span>
                        <span className="checkmark"></span>
                      </label>
                    </li>
                    <li>
                      <label className="cb-container">
                        <input type="checkbox" checked="checked" />
                        <span className="text-small">FREE shipping</span>
                        <span className="checkmark"></span>
                      </label>
                    </li>
                    <li>
                      <label className="cb-container">
                        <input type="checkbox" />
                        <span className="text-small">Big deals</span>
                        <span className="checkmark"></span>
                      </label>
                    </li>
                    <li>
                      <label className="cb-container">
                        <input type="checkbox" />
                        <span className="text-small">Shop Mall</span>
                        <span className="checkmark"></span>
                      </label>
                    </li>
                  </ul>
                  <h6 className="color-gray-900 mb-0 mt-40">
                    Ready to ship in
                  </h6>
                  <ul className="list-checkbox">
                    <li>
                      <label className="cb-container">
                        <input type="checkbox" />
                        <span className="text-small">1 business day</span>
                        <span className="checkmark"></span>
                      </label>
                    </li>
                    <li>
                      <label className="cb-container">
                        <input type="checkbox" checked="checked" />
                        <span className="text-small">
                          1&ndash;3 business days
                        </span>
                        <span className="checkmark"></span>
                      </label>
                    </li>
                    <li>
                      <label className="cb-container">
                        <input type="checkbox" />
                        <span className="text-small">in 1 week</span>
                        <span className="checkmark"></span>
                      </label>
                    </li>
                    <li>
                      <label className="cb-container">
                        <input type="checkbox" />
                        <span className="text-small">Shipping now</span>
                        <span className="checkmark"></span>
                      </label>
                    </li>
                  </ul>
                </div>
                <div className="col-w-1">
                  <h6 className="color-gray-900 mb-0">Ordering options</h6>
                  <ul className="list-checkbox">
                    <li>
                      <label className="cb-container">
                        <input type="checkbox" />
                        <span className="text-small">Accepts gift cards</span>
                        <span className="checkmark"></span>
                      </label>
                    </li>
                    <li>
                      <label className="cb-container">
                        <input type="checkbox" />
                        <span className="text-small">Customizable</span>
                        <span className="checkmark"></span>
                      </label>
                    </li>
                    <li>
                      <label className="cb-container">
                        <input type="checkbox" checked="checked" />
                        <span className="text-small">Can be gift-wrapped</span>
                        <span className="checkmark"></span>
                      </label>
                    </li>
                    <li>
                      <label className="cb-container">
                        <input type="checkbox" />
                        <span className="text-small">Installment 0%</span>
                        <span className="checkmark"></span>
                      </label>
                    </li>
                  </ul>
                  <h6 className="color-gray-900 mb-0 mt-40">Rating</h6>
                  <ul className="list-checkbox">
                    <li className="mb-5">
                      <a href="#">
                        <img src={star} alt="Ecom" />
                        <img src={star} alt="Ecom" />
                        <img src={star} alt="Ecom" />
                        <img src={star} alt="Ecom" />
                        <img src={star} alt="Ecom" />
                        <span className="ml-10 font-xs color-gray-500 d-inline-block align-top">
                          (5 stars)
                        </span>
                      </a>
                    </li>
                    <li className="mb-5">
                      <a href="#">
                        <img src={star} alt="Ecom" />
                        <img src={star} alt="Ecom" />
                        <img src={star} alt="Ecom" />
                        <img src={star} alt="Ecom" />
                        <img src={stargray} alt="Ecom" />
                        <span className="ml-10 font-xs color-gray-500 d-inline-block align-top">
                          (4 stars)
                        </span>
                      </a>
                    </li>
                    <li className="mb-5">
                      <a href="#">
                        <img src={star} alt="Ecom" />
                        <img src={star} alt="Ecom" />
                        <img src={star} alt="Ecom" />
                        <img src={stargray} alt="Ecom" />
                        <img src={stargray} alt="Ecom" />
                        <span className="ml-10 font-xs color-gray-500 d-inline-block align-top">
                          (3 stars)
                        </span>
                      </a>
                    </li>
                    <li className="mb-5">
                      <a href="#">
                        <img src={star} alt="Ecom" />
                        <img src={star} alt="Ecom" />
                        <img src={stargray} alt="Ecom" />
                        <img src={stargray} alt="Ecom" />
                        <img src={stargray} alt="Ecom" />
                        <span className="ml-10 font-xs color-gray-500 d-inline-block align-top">
                          (2 stars)
                        </span>
                      </a>
                    </li>
                    <li className="mb-5">
                      <a href="#">
                        <img src={star} alt="Ecom" />
                        <img src={stargray} alt="Ecom" />
                        <img src={stargray} alt="Ecom" />
                        <img src={stargray} alt="Ecom" />
                        <img src={stargray} alt="Ecom" />
                        <span className="ml-10 font-xs color-gray-500 d-inline-block align-top">
                          (1 star)
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-w-2">
                  <h6 className="color-gray-900 mb-0">Material</h6>
                  <ul className="list-checkbox">
                    <li>
                      <label className="cb-container">
                        <input type="checkbox" />
                        <span className="text-small">Nylon (8)</span>
                        <span className="checkmark"></span>
                      </label>
                    </li>
                    <li>
                      <label className="cb-container">
                        <input type="checkbox" />
                        <span className="text-small">Tempered Glass (5)</span>
                        <span className="checkmark"></span>
                      </label>
                    </li>
                    <li>
                      <label className="cb-container">
                        <input type="checkbox" checked="checked" />
                        <span className="text-small">
                          Liquid Silicone Rubber (5)
                        </span>
                        <span className="checkmark"></span>
                      </label>
                    </li>
                    <li>
                      <label className="cb-container">
                        <input type="checkbox" />
                        <span className="text-small">Aluminium Alloy (3)</span>
                        <span className="checkmark"></span>
                      </label>
                    </li>
                  </ul>
                  <h6 className="color-gray-900 mb-20 mt-40">Product tags</h6>
                  <div>
                    <a className="btn btn-border mr-5" href="#">
                      Games
                    </a>
                    <a className="btn btn-border mr-5" href="#">
                      Electronics
                    </a>
                    <a className="btn btn-border mr-5" href="#">
                      Video
                    </a>
                    <a className="btn btn-border mr-5" href="#">
                      Cellphone
                    </a>
                    <a className="btn btn-border mr-5" href="#">
                      Indoor
                    </a>
                    <a className="btn btn-border mr-5" href="#">
                      VGA Card
                    </a>
                    <a className="btn btn-border mr-5" href="#">
                      USB
                    </a>
                    <a className="btn btn-border mr-5" href="#">
                      Lightning
                    </a>
                    <a className="btn btn-border mr-5" href="#">
                      Camera
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer justify-content-start pl-30">
              <a className="btn btn-buy w-auto" href="#">
                Apply Fillter
              </a>
              <a className="btn font-sm-bold color-gray-500" href="#">
                Reset Fillter
              </a>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="ModalQuickview"
        tabindex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content apply-job-form">
            <button
              className="btn-close"
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
            <div className="modal-body p-30">
              <div className="row">
                <div className="col-lg-6">
                  <div className="gallery-image">
                    <div className="galleries-2">
                      <div className="detail-gallery">
                        <div className="product-image-slider-2">
                          <figure className="border-radius-10">
                            <img src={gallery1} alt="product image" />
                          </figure>
                          <figure className="border-radius-10">
                            <img src={gallery2} alt="product image" />
                          </figure>
                          <figure className="border-radius-10">
                            <img src={gallery3} alt="product image" />
                          </figure>
                          <figure className="border-radius-10">
                            <img src={gallery4} alt="product image" />
                          </figure>
                          <figure className="border-radius-10">
                            <img src={gallery5} alt="product image" />
                          </figure>
                          <figure className="border-radius-10">
                            <img src={gallery6} alt="product image" />
                          </figure>
                          <figure className="border-radius-10">
                            <img src={gallery7} alt="product image" />
                          </figure>
                        </div>
                      </div>
                      <div className="slider-nav-thumbnails-2">
                        <div>
                          <div className="item-thumb">
                            <img src={gallery1} alt="product image" />
                          </div>
                        </div>
                        <div>
                          <div className="item-thumb">
                            <img src={gallery2} alt="product image" />
                          </div>
                        </div>
                        <div>
                          <div className="item-thumb">
                            <img src={gallery3} alt="product image" />
                          </div>
                        </div>
                        <div>
                          <div className="item-thumb">
                            <img src={gallery4} alt="product image" />
                          </div>
                        </div>
                        <div>
                          <div className="item-thumb">
                            <img src={gallery5} alt="product image" />
                          </div>
                        </div>
                        <div>
                          <div className="item-thumb">
                            <img src={gallery6} alt="product image" />
                          </div>
                        </div>
                        <div>
                          <div className="item-thumb">
                            <img src={gallery7} alt="product image" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="box-tags">
                    <div className="d-inline-block mr-25">
                      <span className="font-sm font-medium color-gray-900">
                        Category:
                      </span>
                      <a className="link" href="#">
                        Smartphones
                      </a>
                    </div>
                    <div className="d-inline-block">
                      <span className="font-sm font-medium color-gray-900">
                        Tags:
                      </span>
                      <a className="link" href="#">
                        Blue
                      </a>
                      ,
                      <a className="link" href="#">
                        Smartphone
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="product-info">
                    <h5 className="mb-15">
                      SAMSUNG Galaxy S22 Ultra, 8K Camera & Video, Brightest
                      Display Screen, S Pen Pro
                    </h5>
                    <div className="info-by">
                      <span className="bytext color-gray-500 font-xs font-medium">
                        by
                      </span>
                      <a
                        className="byAUthor color-gray-900 font-xs font-medium"
                        href="shop-vendor-list.html"
                      >
                        {" "}
                        Ecom Tech
                      </a>
                      <div className="rating d-inline-block">
                        <img src={star} alt="Ecom" />
                        <img src={star} alt="Ecom" />
                        <img src={star} alt="Ecom" />
                        <img src={star} alt="Ecom" />
                        <img src={star} alt="Ecom" />
                        <span className="font-xs color-gray-500 font-medium">
                          {" "}
                          (65 reviews)
                        </span>
                      </div>
                    </div>
                    <div className="border-bottom pt-10 mb-20"></div>
                    <div className="box-product-price">
                      <h3 className="color-brand-3 price-main d-inline-block mr-10">
                        $2856.3
                      </h3>
                      <span className="color-gray-500 price-line font-xl line-througt">
                        $3225.6
                      </span>
                    </div>
                    <div className="product-description mt-10 color-gray-900">
                      <ul className="list-dot">
                        <li>8k super steady video</li>
                        <li>Nightography plus portait mode</li>
                        <li>50mp photo resolution plus bright display</li>
                        <li>Adaptive color contrast</li>
                        <li>premium design & craftmanship</li>
                        <li>Long lasting battery plus fast charging</li>
                      </ul>
                    </div>
                    <div className="box-product-color mt-10">
                      <p className="font-sm color-gray-900">
                        Color:
                        <span className="color-brand-2 nameColor">
                          Pink Gold
                        </span>
                      </p>
                      <ul className="list-colors">
                        <li className="disabled">
                          <img src={gallery1} alt="Ecom" title="Pink" />
                        </li>
                        <li>
                          <img src={gallery2} alt="Ecom" title="Gold" />
                        </li>
                        <li>
                          <img src={gallery3} alt="Ecom" title="Pink Gold" />
                        </li>
                        <li>
                          <img src={gallery4} alt="Ecom" title="Silver" />
                        </li>
                        <li className="active">
                          <img src={gallery5} alt="Ecom" title="Pink Gold" />
                        </li>
                        <li className="disabled">
                          <img src={gallery6} alt="Ecom" title="Black" />
                        </li>
                        <li className="disabled">
                          <img src={gallery7} alt="Ecom" title="Red" />
                        </li>
                      </ul>
                    </div>
                    <div className="box-product-style-size mt-10">
                      <div className="row">
                        <div className="col-lg-12 mb-10">
                          <p className="font-sm color-gray-900">
                            Style:
                            <span className="color-brand-2 nameStyle">S22</span>
                          </p>
                          <ul className="list-styles">
                            <li className="disabled" title="S22 Ultra">
                              S22 Ultra
                            </li>
                            <li className="active" title="S22">
                              S22
                            </li>
                            <li title="S22 + Standing Cover">
                              S22 + Standing Cover
                            </li>
                          </ul>
                        </div>
                        <div className="col-lg-12 mb-10">
                          <p className="font-sm color-gray-900">
                            Size:
                            <span className="color-brand-2 nameSize">
                              512GB
                            </span>
                          </p>
                          <ul className="list-sizes">
                            <li className="disabled" title="1GB">
                              1GB
                            </li>
                            <li className="active" title="512 GB">
                              512 GB
                            </li>
                            <li title="256 GB">256 GB</li>
                            <li title="128 GB">128 GB</li>
                            <li className="disabled" title="64GB">
                              64GB
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="buy-product mt-5">
                      <p className="font-sm mb-10">Quantity</p>
                      <div className="box-quantity">
                        <div className="input-quantity">
                          <input
                            className="font-xl color-brand-3"
                            type="text"
                            value="1"
                          />
                          <span className="minus-cart"></span>
                          <span className="plus-cart"></span>
                        </div>
                        <div className="button-buy">
                          <a className="btn btn-cart" href="shop-cart.html">
                            Add to cart
                          </a>
                          <a className="btn btn-buy" href="shop-checkout.html">
                            Buy now
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
export default Shopcart;
