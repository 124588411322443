import * as Yup from "yup";

const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/;
// min 5 characters, 1 upper case letter, 1 lower case letter, 1 numeric digit.

export const signupValidator = Yup.object().shape({
  name: Yup.string().required("Name is Required"),
  email: Yup.string().required("Mail is Required"),
  password: Yup.string()
    .matches(passwordRules, {
      message:
        "Please create a stronger password(min 5 characters, 1 upper case letter, 1 lower case letter, 1 numeric digit)",
    })
    .required("Required"),
});
export const signinValidator = Yup.object().shape({
  email: Yup.string().required("Mail is Required"),
  password: Yup.string().required("Required"),
});

export const emailValidator = Yup.object().shape({
  email: Yup.string().email().required("Mail is Required"),
});
export const loginOtpValidator = Yup.object().shape({
  email: Yup.string().email().required("Email is Required"),
  otp: Yup.string().required("OTP is Required"),
});

export const userUpdateValidator = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  email: Yup.string().email("Invalid email format").required("Email is required"),
  mobile: Yup.string()
    .matches(/^[0-9]{10}$/, "Mobile number must be 10 digits")
    .required("Mobile number is required"),
})
export const supportValidator = Yup.object().shape({
  type: Yup.string().required("Type is required"),
  message: Yup.string().required("Message is required"),
 
})
const validateEmail = (email) => {
  return Yup.string().email().isValidSync(email);
};

const validatePhone = (phone) => {
  return Yup
    .number()
    .integer()
    .positive()
    .test((phone) => {
      return phone && phone.toString().length == 10 ? true : false;
    })
    .isValidSync(phone);
};

export const contactUsValidationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  email: Yup
    .string()
    .required("Email is required")
    .test("email", "Email is invalid", (value) => {
      return validateEmail(value);
    }),
  phone_number: Yup
    .string()
    .required("Phone number is required")
    .test("phone", "Phone is invalid", (value) => {
      return validatePhone(parseInt(value ?? "0"));
    }),
  message: Yup.string(),
});