import { secretKey } from "../../services/config";

import EncryptDecrypt from "../../utils/encrypt&decrypt";
import { accountActionTypes, accountInitialState } from "./account.model";

const reducer = (state = accountInitialState, action) => {
  switch (action.type) {
    case accountActionTypes.ACC_GET_REQUEST:
      return {
        ...state,
        getAccLoading: true,
      };
    case accountActionTypes.ACC_GET_SUCCESS:
        console.log(action,'123456');
        
      return {
        ...state,
       
        getAccLoading:false,
        AccData:action?.payload?.data?.data,
      };
    case accountActionTypes.ACC_GET_FAILURE:
      return {
        ...state,
        getAccLoading: false,
      };
      default:
        return state
    }
}
export {reducer as accReducer}