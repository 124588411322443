import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Row, Table } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import Footer from "../Footer";
import Header from "../Header";
import Sidebar from "../Sidebar";
import Select from "react-select";
import {
  getUserDataRequest,
  logout,
  updateUserRequest,
} from "../../store/user/user.action";
import { userSelector } from "../../store/user/user.model";
import {
  supportValidator,
  userUpdateValidator,
} from "../../validators/Validators";
import {
  resetstate,
  riseGetRequest,
  risePostRequest,
} from "../../store/risetocken/rise.action";
import {
  statusClasses,
  supportStuts,
  typeOptions,
} from "../../utils/Constants";
import { toast } from "react-toastify";
import { RiseTockenSelector } from "../../store/risetocken/rise.model";
import { getOrderDetailRequest } from "../../store/cart/cart.action";
import { cartSelector } from "../../store/cart/cart.model";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function RiseTocken() {
  const dispatch = useDispatch();
  const id = localStorage.getItem("id");
  const { orderDetailData } = useSelector(cartSelector);
  const [showRemarks, setShowRemarks] = useState(false);
  const [viewRemarks, setViewRemarks] = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(supportValidator),
  });

  /* Selectors */
  const { postSuccess, tocken } = useSelector(RiseTockenSelector);
  console.log(tocken, postSuccess, "valueskk");
  /* Function */

  const onSubmitHandler = (formData) => {
    formData.user_id = id;
    delete formData.Message;
    delete formData.Type;
    delete formData.id;
    console.log(formData, "lll");
    dispatch(risePostRequest(formData));
  };

  /* useEfffect */
  useEffect(() => {
    const payload = {
      page: 0,
      limit: 0,
      query: "",
      customerId: id,
    };
    dispatch(riseGetRequest(payload));
    dispatch(getOrderDetailRequest());
  }, []);
  useEffect(() => {
    if (postSuccess) {
      dispatch(resetstate());
      toast.dismiss();
      toast.success("Your Ticket Raise Confirmed");
      const payload = {
        page: 0,
        limit: 0,
        query: "",
        customerId: id,
      };
      dispatch(riseGetRequest(payload));
    }
  }, [postSuccess]);
  const handleRClose = () => {
    setShowRemarks(!showRemarks);
  };

  return (
    <>
      <Header />
      <Sidebar />
      <section class="section-box shop-template mt-30">
        <Container class="box-account-template">
          <div class="box-tabs mb-100">
            <h4> Raise Ticket</h4>
            <div class="border-bottom mt-20 mb-40"></div>
            <div class="tab-content mt-30">
              <Row className="justify-content-center">
                <Col lg={12} class="mb-20">
                  <form onSubmit={handleSubmit(onSubmitHandler)}>
                    <Row>
                      <Col lg={3} class="mb-20">
                        <Row>
                          <Col lg={12}>
                            <h5 class="font-md-bold color-brand-3 mt-15 mb-20">
                              Type
                            </h5>
                          </Col>
                          <Col lg={12}>
                            <div class="form-group">
                              <select
                                name=""
                                id=""
                                {...register("type")}
                                className="form-control"
                              >
                                <option value="" disabled>
                                  Select
                                </option>
                                <option value={"0"}>Order</option>
                                <option value={"1"}>Payment</option>
                              </select>
                              {errors?.type?.message && (
                                <div>
                                  <p className="error text-danger">
                                    {errors?.type?.message}
                                  </p>
                                </div>
                              )}
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col lg={3} class="mb-20">
                        <Row>
                          <Col lg={12}>
                            <h5 class="font-md-bold color-brand-3 mt-15 mb-20">
                              Invoice No
                            </h5>
                          </Col>
                          <Col lg={12}>
                            <div class="form-group">
                              <select
                                name=""
                                id=""
                                {...register("invoice_number")}
                                className="form-control"
                              >
                                <option value="">Select</option>
                                {orderDetailData &&
                                  orderDetailData.map((ele, index) => {
                                    return (
                                      <option value={ele?.order_no}>
                                        {ele?.order_no}
                                      </option>
                                    );
                                  })}
                              </select>
                              {errors?.invoice_number?.message && (
                                <div>
                                  <p className="error text-danger">
                                    {errors?.invoice_number?.message}
                                  </p>
                                </div>
                              )}
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col lg={6} class="mb-20">
                        <Row>
                          <Col lg={12}>
                            <h5 class="font-md-bold color-brand-3 mt-15 mb-20">
                              Message
                            </h5>
                          </Col>
                          <Col lg={12}>
                            <div class="form-group">
                              <textarea
                                placeholder="Enter the Messgae"
                                className="form-control w-100"
                                {...register("message")}
                              ></textarea>
                              {errors?.message?.message && (
                                <div>
                                  <p className="error text-danger">
                                    {errors?.message?.message}
                                  </p>
                                </div>
                              )}
                            </div>
                          </Col>
                        </Row>
                      </Col>

                      <Col lg={12}>
                        <div class="form-group mt-20">
                          <button
                            class="btn btn-buy w-auto h54 font-md-bold"
                            type="submit"
                          >
                            Submit
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </form>
                </Col>
              </Row>
              <Table>
                <thead>
                  <tr>
                    <td>Type</td>
                    <td>Invoice Number</td>
                    <td>Message</td>
                    <td>Status</td>
                    <td>View Admin Remarks</td>
                  </tr>
                </thead>
                <tbody>
                  {tocken &&
                    tocken?.map((c, index) => {
                      return (
                        <tr>
                          <td>
                            <label>{c?.type === 0 ? "Order" : "Payment"}</label>
                          </td>
                          <td>
                            <label>{c?.invoice_number}</label>
                          </td>
                          <td>
                            <label>{c?.message}</label>
                          </td>
                          <td>
                            <span
                              className={`badge rounded-pill ${
                                statusClasses[c?.message_status] ||
                                "alert-secondary"
                              }`}
                            >
                              {supportStuts[c?.message_status]}
                            </span>
                          </td>
                          <td>
                            <Button
                              variant="primary"
                              onClick={(e) => {
                                setViewRemarks(c?.remarks);
                                setShowRemarks(!showRemarks);
                              }}
                            >
                              View Remarks
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>

              <Modal show={showRemarks} onHide={handleRClose}>
                <Modal.Header closeButton>
                  <Modal.Title>Remarks View</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="d-flex flex-column ">
                    <Table>
                      <thead>
                        <tr>
                          <td>Remarks</td>
                        </tr>
                      </thead>
                      <tbody>
                        {viewRemarks &&
                          viewRemarks.map((remark) => (
                            <tr>
                              <td>{remark}</td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleRClose}>
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </div>
        </Container>
      </section>
      <section class="section-box box-newsletter">
        <Container>
          <Row>
            <Col lg={6}>
              <h3 class="color-white">
                Subscrible &amp; Get <span class="color-warning">10%</span>{" "}
                Discount
              </h3>
              <p class="font-lg color-white">
                Get E-mail updates about our latest shop and{" "}
                <span class="font-lg-bold">special offers.</span>
              </p>
            </Col>
            <Col lg={4} md={5} sm={12}>
              <div class="box-form-newsletter mt-15">
                <form class="form-newsletter">
                  <input
                    class="input-newsletter font-xs"
                    value=""
                    placeholder="Your email Address"
                  />
                  <button class="btn btn-brand-2">Sign Up</button>
                </form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </>
  );
}
export default RiseTocken;
