import { call, all, put, takeEvery } from "redux-saga/effects";

import * as cartActions from "./cart.action";
import { cartActionTypes } from "./cart.model";
import {
  addCardApi,
  checkOutApi,
  destoryCartApi,
  failPayment,
  getCartApi,
  getOrderDetialApi,
  onlineCheckOutApi,
  removeCartApi,
  verifyPayment,
} from "./cart.api";
import { loadScript } from "../../services/config";
import Logo from "../../assets/imgs/sikan-logo.png"
import store from "../store";
export function* handleGetCarts(action) {
  console.log(action.payload, "6789");
  try {
    const response = yield call(getCartApi, action.payload);
    yield put(cartActions.cartGetSuccess(response));
  } catch (e) {
    yield put(cartActions.cartGetFailure(e));
  }
}
export function* handleRemoveCarts(action) {
  console.log(action, "6789");
  try {
    const response = yield call(removeCartApi, action.payload);
    yield put(cartActions.cartRemoveSuccess(response));
  } catch (e) {
    yield put(cartActions.cartRemoveFailure(e));
  }
}
export function* handledestroyCarts(action) {
  console.log(action, "6789");
  try {
    const response = yield call(destoryCartApi, action.payload);
    yield put(cartActions.cartDestroySuccess(response));
  } catch (e) {
    yield put(cartActions.cartDestroyFailure(e));
  }
}

export function* handlePostToCard(action) {
  try {
    const response = yield call(addCardApi, action.payload);
    yield put(cartActions.postAddToCardsuccess(response));
  } catch (err) {
    yield put(cartActions.postAddToCardFailure(err));
  }
}
export function* handleCheckout(action) {
  try {
    const response = yield call(checkOutApi, action.payload);
    yield put(cartActions.checkOutDataSuccess(response));
  } catch (err) {
    yield put(cartActions.checkOutDataFailure(err));
  }
}
export function* handlegetOrders(action) {
  try {
    const response = yield call(getOrderDetialApi, action.payload);
    yield put(cartActions.getOrderDetailSuccess(response));
  } catch (err) {
    yield put(cartActions.getOrderDetailFailure(err));
  }
}

function* handlepayOnline(action) {
  const { datas, userData } = action.payload;
  console.log(action.payload, "pay78");

  try {
      const sdkLoaded = yield call(loadScript, 'https://checkout.razorpay.com/v1/checkout.js');
      if (!sdkLoaded) {
          yield put(cartActions.displayRazorPayFailure("Razorpay SDK load failure"));
          return;
      }
      datas.total_price = `${Number(datas.total_price) * 100}`
      const checkoutResponse = yield call(onlineCheckOutApi, { datas });
      console.log(checkoutResponse, "checkout response");

      if (!checkoutResponse?.data?.order) {
          yield put(cartActions.displayRazorPayFailure("Failed to create order"));
          return;
      }

      const orderId = checkoutResponse.data.order.id;
      const amountDue = checkoutResponse.data.order.amount_due;

      const options = {
          key: process.env.RAZORPAY_KEY_ID,
          amount: `${amountDue}`,
          currency: 'INR',
          name: 'SIKAN PVC Pipes & Bend',
          description: 'Transaction',
          image: Logo,
          order_id: orderId,
          handler: (response) => {
              const paymentData = {
                  orderCreationId: orderId,
                  razorpayPaymentId: response.razorpay_payment_id,
                  razorpayOrderId: response.razorpay_order_id,
                  razorpaySignature: response.razorpay_signature,
                  ...datas,
              };
              store.dispatch(cartActions.makePaymentRequest(paymentData));
          },
          prefill: {
              name: userData[0]?.name,
          },
          notes: {
              address: userData[0]?.address,
          },
          theme: {
              color: '#61dafb',
          },
      };

      const paymentObject = new window.Razorpay(options);

      paymentObject.on("payment.failed", () => {
          store.dispatch(cartActions.makePaymentFailure("Payment failed"));
          store.dispatch(failPayment({ data: orderId, cartDetails: datas?.cartDetails }));
      });

      paymentObject.open();

      yield put(cartActions.displayRazorPaySuccess(checkoutResponse));
  } catch (error) {
      console.error("Error during payment:", error);
      yield put(cartActions.displayRazorPayFailure(error.message));
  }
}

export function* handlepaymentSuccess(action) {
  try {
    const response = yield call(verifyPayment, action.payload);
    yield put(cartActions.makePaymentSuccess(response));
  } catch (err) {
    yield put(cartActions.makePaymentFailure(err));
  }
}
export function* cartSaga() {
  yield takeEvery(cartActionTypes.CARTS_GET_REQUEST, handleGetCarts);
  yield takeEvery(cartActionTypes.CARTS_REMOVE_REQUEST, handleRemoveCarts);
  yield takeEvery(cartActionTypes.CARTS_DESTROY_REQUEST, handledestroyCarts);
  yield takeEvery(cartActionTypes.ADDTOCART_POST_REQUEST, handlePostToCard);
  yield takeEvery(cartActionTypes.CHECKOUT_POST_REQUEST, handleCheckout);
  yield takeEvery(cartActionTypes.ORDERDETAIL_GET_REQUEST, handlegetOrders);
  yield takeEvery(cartActionTypes.DISPLAYRAZORPAY_REQUEST, handlepayOnline);
  yield takeEvery(cartActionTypes.MAKEPAYMENT_REQUEST, handlepaymentSuccess);
}
