export const RiseTockenActionTypes = {
  RISETOCKEN_GET_REQUEST: "@product/RISETOCKEN_GET_REQUEST",
  RISETOCKEN_GET_SUCCESS: "@product/RISETOCKEN_GET_SUCCESS",
  RISETOCKEN_GET_FAILURE: "@product/RISETOCKEN_GET_FAILURE",

  RISETOCKEN_POST_REQUEST: "@product/RISETOCKEN_POST_REQUEST",
  RISETOCKEN_POST_SUCCESS: "@product/RISETOCKEN_POST_SUCCESS",
  RISETOCKEN_POST_FAILURE: "@product/RISETOCKEN_POST_FAILURE",

  RESTSET_STATE:"@@rise/RESTSET_STATE"


};

export const RiseTockenInitialState = {
  getLoading:false,
  tocken:[],
  postLoading:false,
  postSuccess:false

};
export const RiseTockenSelector = (state) => state.RiseTockenReducer;
