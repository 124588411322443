import { action } from "typesafe-actions";
import { categoryAddActionType } from "./category.model";

export const postCatRequest = (req) =>
  action(categoryAddActionType.POSTCATEGORY_REQUEST, req);
export const postCatSuccess = (res) =>
  action(categoryAddActionType.POSTCATEGORY_SUCCESS, res);
export const postCatFailure = (err) =>
  action(categoryAddActionType.POSTCATEGORY_FAILURE, err);


export const updateCatRequest = (req) =>
  action(categoryAddActionType.UPDATECATEGORY_REQUEST, req);
export const updateCatSuccess = (res) =>
  action(categoryAddActionType.UPDATECATEGORY_SUCCESS, res);
export const updateCatFailure = (err) =>
  action(categoryAddActionType.UPDATECATEGORY_FAILURE, err);


export const deleteCatRequest = (req) =>
  action(categoryAddActionType.DELETECATEGORY_REQUEST, req);
export const deleteCatSuccess = (res) =>
  action(categoryAddActionType.DELETECATEGORY_SUCCESS, res);
export const deleteCatFailure = (err) =>
  action(categoryAddActionType.DELETECATEGORY_FAILURE, err);

export const getCatRequest = (req) =>
  action(categoryAddActionType.GETCATEGORY_REQUEST, req);
export const getCatSuccess = (res) =>
  action(categoryAddActionType.GETCATEGORY_SUCCESS, res);
export const getCatFailure = (err) =>
  action(categoryAddActionType.GETCATEGORY_FAILURE, err);


