import { call, all, put, takeEvery } from "redux-saga/effects";
import { ProductActionTypes } from "./products.model";
import {
  getBrandApi,
  getProdcategoryApi,
  getProductApi,
  mailSendApi,
} from "./products.api";
import * as productActions from "./products.action";
export function* handleGetProducts(action) {
  console.log(action.payload, "6789");
  try {
    const response = yield call(getProductApi, action.payload);
    yield put(productActions.productGetSuccess(response));
  } catch (e) {
    yield put(productActions.productGetFailure(e));
  }
}

export function* handleGetProdCategory() {
  try {
    const response = yield call(getProdcategoryApi);
    yield put(productActions.prodCategoryGetSuccess(response));
  } catch (e) {
    yield put(productActions.prodCategoryGetFailure(e));
  }
}

export function* handleGetBrands() {
  try {
    const response = yield call(getBrandApi);
    yield put(productActions.BrandDataGetSuccess(response));
  } catch (error) {
    yield put(productActions.BrandDataGetFailure(error));
  }
}
export function* handlePostDataEmail(action) {
  try {
    const response = yield call(mailSendApi, action.payload);
    yield put(productActions.productDataEmailPostSuccess(response));
  } catch (err) {
    yield put(productActions.productDataEmailPostFailure(err));
  }
}

export function* handleGetFilterProdCategory(action) {
  try {
    const response = yield call(getProductApi, action.payload);
    yield put(productActions.filterProductGetSuccess(response));
  } catch (e) {
    yield put(productActions.filterProductGetFailure(e));
  }
}
export function* productSaga() {
  yield takeEvery(ProductActionTypes.PRODUCTS_GET_REQUEST, handleGetProducts);
  yield takeEvery(
    ProductActionTypes.PRODUCTCATEGORY_GET_REQUEST,
    handleGetProdCategory
  );
  yield takeEvery(ProductActionTypes.GETBRANDS_GET_REQUEST, handleGetBrands);
  yield takeEvery(
    ProductActionTypes.MAILPRODUCTDETIALS_GET_REQUEST,
    handlePostDataEmail
  ); yield takeEvery(
    ProductActionTypes.FILTERPRODUCTS_GET_REQUEST,
    handleGetFilterProdCategory
  );
}
