export const categoryAddActionType = {
    POSTCATEGORY_REQUEST: "@@categorypage/POSTCATEGORY_REQUEST",
    POSTCATEGORY_SUCCESS: "@@categorypage/POSTCATEGORY_SUCCESS",
    POSTCATEGORY_FAILURE: "@@categorypage/POSTCATEGORY_FAILURE",
  
    GETCATEGORY_REQUEST: "@@categorypage/GETCATEGORY_REQUEST",
    GETCATEGORY_SUCCESS: "@@categorypage/GETCATEGORY_SUCCESS",
    GETCATEGORY_FAILURE: "@@categorypage/GETCATEGORY_FAILURE",
  
    UPDATECATEGORY_REQUEST: "@@categorypage/UPDATECATEGORY_REQUEST",
    UPDATECATEGORY_SUCCESS: "@@categorypage/UPDATECATEGORY_SUCCESS",
    UPDATECATEGORY_FAILURE: "@@categorypage/UPDATECATEGORY_FAILURE",
  
  
    DELETECATEGORY_REQUEST: "@@categorypage/DELETECATEGORY_REQUEST",
    DELETECATEGORY_SUCCESS: "@@categorypage/DELETECATEGORY_SUCCESS",
    DELETECATEGORY_FAILURE: "@@categorypage/DELETECATEGORY_FAILURE",
}

export const catAddInitialState = {
    postLoading: false,
    getLoading: false,
    sendSuccess: false,
    getCatData: [],
    updateCatLoading:false,
    catUpdatedSuccess:false,
    deleteCatLoading:false,
    catDeleteSuccess:false,
}

export const CatAddSelector = (state) => state.CategoryAddReducer;
