import { getBaseEndpointUrl } from "../../services/config";
import { Http } from "../../services/Http";

export const getTockensApi = (payload) => {
  console.log(payload, "payloaddf");
  const baseUrl = getBaseEndpointUrl();
  return Http.get(
    baseUrl +
      "/support?page=" +
      payload.page +
      "&limit=" +
      payload.limit +
      "&query=" +
      payload.query +
      "&customerId=" +
      payload.customerId,
    Http.getAuthToken()
  );
};
export const postTockenApi = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(baseUrl + "/support", formData, Http.getAuthToken());
};
