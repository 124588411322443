import React from "react";
import { Col, Container, Row, Modal, Button } from "react-bootstrap";
import { useLocation, useParams, Link } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import Sidebar from "./Sidebar";

const PrivacyPolicy = () => {
  return (
    <div>
      <Header />
      <Sidebar />
      <div className="section-box">
        <div className="breadcrumbs-div">
          <Container>
            <ul className="breadcrumb">
              <li>
                <Link to="/" className="font-xs color-gray-1000">
                  <span>Home</span>
                </Link>
              </li>
              {/* <li>
                <a className="font-xs color-gray-500" href="shop-grid-2.html">
                  Electronics
                </a>
              </li>
              <li>
                <a className="font-xs color-gray-500" href="shop-grid-2.html">
                  Cell phone
                </a>
              </li> */}
              <li>
                <Link to="/purchase" className="font-xs color-gray-500">
                  Purchase
                </Link>
              </li>
            </ul>
          </Container>
        </div>
      </div>

      <section class="privacy_section mb-30">
        <div class="container">
          <div class="policies">
            <h4 className="mt-10">
              <strong>Privacy Policy for Sikan PVC Pipes & Bend</strong>
            </h4>

            <p>
              At Sikan PVC Pipes & Bend, accessible from harvestfresh.com, one
              of our main priorities is the privacy of our visitors. This
              Privacy Policy document contains types of information that is
              collected and recorded by Sikan PVC Pipes & Bend and how we use
              it.
            </p>

            <p>
              If you have additional questions or require more information about
              our Privacy Policy, do not hesitate to contact us.
            </p>

            <h4 className="mt-10">
              <strong>Log Files</strong>
            </h4>

            <p>
              Sikan PVC Pipes & Bend follows a standard procedure of using log
              files. These files log visitors when they visit websites. All
              hosting companies do this and a part of hosting services'
              analytics. The information collected by log files include internet
              protocol (IP) addresses, browser type, Internet Service Provider
              (ISP), date and time stamp, referring/exit pages, and possibly the
              number of clicks. These are not linked to any information that is
              personally identifiable. The purpose of the information is for
              analyzing trends, administering the site, tracking users' movement
              on the website, and gathering demographic information.{" "}
            </p>

            <h4 className="mt-10">
              <strong>Cookies and Web Beacons</strong>
            </h4>

            <p>
              Like any other website, Sikan PVC Pipes & Bend uses "cookies".
              These cookies are used to store information including visitors'
              preferences, and the pages on the website that the visitor
              accessed or visited. The information is used to optimize the
              users' experience by customizing our web page content based on
              visitors' browser type and/or other information.
            </p>

            <p>
              For more general information on cookies, please read{" "}
              <a href="https://www.privacypolicyonline.com/what-are-cookies/">
                the "Cookies" article from the Privacy Policy Generator
              </a>
              .
            </p>
            {/* >>>>>>> parent of e1b9ab7 ([brands] Updated credits link) */}

            <h4 className="mt-10">
              <strong>Privacy Policies</strong>
            </h4>

            <p>
              You may consult this list to find the Privacy Policy for each of
              the advertising partners of Sikan PVC Pipes & Bend.
            </p>

            <p>
              Third-party ad servers or ad networks uses technologies like
              cookies, JavaScript, or Web Beacons that are used in their
              respective advertisements and links that appear on Sikan PVC Pipes
              & Bend, which are sent directly to users' browser. They
              automatically receive your IP address when this occurs. These
              technologies are used to measure the effectiveness of their
              advertising campaigns and/or to personalize the advertising
              content that you see on websites that you visit.
            </p>

            <p>
              Note that Sikan PVC Pipes & Bend has no access to or control over
              these cookies that are used by third-party advertisers.
            </p>

            <h4 className="mt-10">
              <strong>Third Party Privacy Policies</strong>
            </h4>

            <p>
              Sikan PVC Pipes & Bend's Privacy Policy does not apply to other
              advertisers or websites. Thus, we are advising you to consult the
              respective Privacy Policies of these third-party ad servers for
              more detailed information. It may include their practices and
              instructions about how to opt-out of certain options.{" "}
            </p>

            <p>
              You can choose to disable cookies through your individual browser
              options. To know more detailed information about cookie management
              with specific web browsers, it can be found at the browsers'
              respective websites. What Are Cookies?
            </p>

            <h4 className="mt-10">
              <strong>Children's Information</strong>
            </h4>

            <p>
              Another part of our priority is adding protection for children
              while using the internet. We encourage parents and guardians to
              observe, participate in, and/or monitor and guide their online
              activity.
            </p>

            <p>
              Sikan PVC Pipes & Bend does not knowingly collect any Personal
              Identifiable Information from children under the age of 13. If you
              think that your child provided this kind of information on our
              website, we strongly encourage you to contact us immediately and
              we will do our best efforts to promptly remove such information
              from our records.
            </p>

            <h4 className="mt-10">
              <strong>Online Privacy Policy Only</strong>
            </h4>

            <p>
              This Privacy Policy applies only to our online activities and is
              valid for visitors to our website with regards to the information
              that they shared and/or collect in Sikan PVC Pipes & Bend. This
              policy is not applicable to any information collected offline or
              via channels other than this website.
            </p>

            <h4 className="mt-10">
              <strong>Consent</strong>
            </h4>

            <p>
              By using our website, you hereby consent to our Privacy Policy and
              agree to its Terms and Conditions.
            </p>
          </div>
        </div>
      </section>
      {/* </div> */}
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
