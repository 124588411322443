import { secretKey } from "../../services/config";
import EncryptDecrypt from "../../utils/encrypt&decrypt";
import { ProductActionTypes, productInitialState } from "./products.model";

const reducer = (state = productInitialState, action) => {
  switch (action.type) {
    case ProductActionTypes.PRODUCTS_GET_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ProductActionTypes.PRODUCTS_GET_SUCCESS:
      let payloadData = JSON.parse(
        EncryptDecrypt.decryptdata(action?.payload.data?.datas, secretKey)
      );
      return {
        ...state,
        loading: false,
        getProducts: payloadData,
      };
    case ProductActionTypes.PRODUCTS_GET_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case ProductActionTypes.PRODUCTCATEGORY_GET_REQUEST:
      return {
        ...state,
        categoryLoad: true,
      };
    case ProductActionTypes.PRODUCTCATEGORY_GET_SUCCESS:
      let payloadData1 = JSON.parse(
        EncryptDecrypt.decryptdata(action?.payload.data?.datas, secretKey)
      );
      return {
        ...state,
        categoryLoad: false,
        getCategoryList: payloadData1?.data,
      };
    case ProductActionTypes.PRODUCTCATEGORY_GET_FAILURE:
      return {
        ...state,
        categoryLoad: false,
      };
    case ProductActionTypes.GETBRANDS_GET_REQUEST:
      return {
        ...state,
        getBrandLoad: true,
      };
    case ProductActionTypes.GETBRANDS_GET_SUCCESS:
      let payloadData2 = JSON.parse(
        EncryptDecrypt.decryptdata(action?.payload.data?.datas, secretKey)
      );
      return {
        ...state,
        getBrandLoad: false,
        getBrandList: payloadData2?.data,
      };
    case ProductActionTypes.GETBRANDS_GET_FAILURE:
      return {
        ...state,
        getBrandLoad: false,
      };
    case ProductActionTypes.MAILPRODUCTDETIALS_GET_REQUEST:
      return {
        sendMailLoad: true,
        mailSendSuccess: false,
      };
    case ProductActionTypes.MAILPRODUCTDETIALS_GET_SUCCESS:
      return {
        sendMailLoad: false,
        mailSendSuccess: true,
      };
    case ProductActionTypes.MAILPRODUCTDETIALS_GET_FAILURE:
      return {
        sendMailLoad: false,
        mailSendSuccess: false,
      };

    case ProductActionTypes.STATE_RESET_REQUEST:
      return {
        ...state,
        addCardLoad: false,
        succesMessage: "",
      };
    case ProductActionTypes.FILTERPRODUCTS_GET_REQUEST:
      return {
        ...state,
        filterLoad: true,
      };
    case ProductActionTypes.FILTERPRODUCTS_GET_SUCCESS:
      let payloadData3 = JSON.parse(
        EncryptDecrypt.decryptdata(action?.payload.data?.datas, secretKey)
      );
      return {
        ...state,
        filterLoad: false,
        filterProductData: payloadData3?.data,
      };
    case ProductActionTypes.FILTERPRODUCTS_GET_FAILURE:
      return {
        ...state,
        filterLoad: false,
      };
    default:
      return state;
  }
};
export { reducer as productReducer };
