import { secretKey } from "../../services/config";
import { cartActionTypes, cartInitialState } from "./cart.model";
import EncryptDecrypt from "../../utils/encrypt&decrypt";
import alertmessage from "../../utils/alert";

const reducer = (state = cartInitialState, action) => {
  switch (action.type) {
    case cartActionTypes.CARTS_GET_REQUEST:
      return {
        ...state,
        cartloading: true,
      };
    case cartActionTypes.CARTS_GET_SUCCESS:
      return {
        ...state,
        cartloading: false,
        getCarts: action?.payload?.data?.datas?.data,
      };
    case cartActionTypes.CARTS_GET_FAILURE:
      console.log(action.payload.response.data.message,'jflglfjs');
      let expired=""
      if(action.payload.response.data.message ==="Your session expired! Please login to continue!"){
        console.log("jflglfjs");
        
        alertmessage.sweetalert(action.payload.response.data.message)
        // expired=action.payload.response.data.message
      }
      return {
        ...state,
        AuthExpried:expired,
        cartloading: false,
      };
      case cartActionTypes.CARTS_REMOVE_REQUEST:
        return {
          ...state,
          removeCartLoading:true,
          removeCartsuccess:false,
        };
      case cartActionTypes.CARTS_REMOVE_SUCCESS:
        return {
          ...state,
          removeCartLoading:false,
          removeCartsuccess:true,
        };
      case cartActionTypes.CARTS_REMOVE_FAILURE:
        return {
          ...state,
          removeCartLoading:false,
          removeCartsuccess:false,
        };

        case cartActionTypes.CARTS_DESTROY_REQUEST:
          return {
            ...state,
            destroyCartLoading:true,
            destroyCartsuccess:false,
          };
        case cartActionTypes.CARTS_DESTROY_SUCCESS:
          return {
            ...state,
            destroyCartLoading:false,
            destroyCartsuccess:true,
          };
        case cartActionTypes.CARTS_DESTROY_FAILURE:
          return {
            ...state,
            destroyCartLoading:false,
            destroyCartsuccess:false,
          };
    case cartActionTypes.ADDTOCART_POST_REQUEST:
      return {
        ...state,
        addCardLoad: true,
      };
    case cartActionTypes.ADDTOCART_POST_SUCCESS:
      return {
        ...state,
        addCardLoad: false,
        succesMessage: action?.payload?.data?.message,
      };
    case cartActionTypes.ADDTOCART_POST_FAILURE:
      return {
        ...state,
        addCardLoad: false,
      };

    case cartActionTypes.CHECKOUT_POST_REQUEST:
      return {
        ...state,
        checkOutLoad: true,
      };
    case cartActionTypes.CHECKOUT_POST_SUCCESS:
      return {
        ...state,
        checkOutLoad: false,
        succesMessage: action?.payload?.data?.message,
        Purchasesuccess:true,
      };
    case cartActionTypes.CHECKOUT_POST_FAILURE:
      return {
        ...state,
        checkOutLoad: false,
      };
    case cartActionTypes.ORDERDETAIL_GET_REQUEST:
      return {
        ...state,
        checkOutLoad: true,
      };
    case cartActionTypes.ORDERDETAIL_GET_SUCCESS:
      let payloadData = JSON.parse(
        EncryptDecrypt.decryptdata(action?.payload.data?.datas, secretKey)
      );
      return {
        ...state,
        ordergetLoad: false,
        orderDetailData: payloadData?.data,
      };
    case cartActionTypes.ORDERDETAIL_GET_FAILURE:
      return {
        ...state,
        ordergetLoad: false,
      };
    case cartActionTypes.DISPLAYRAZORPAY_REQUEST:
      return {
        ...state,
        onlinecheckLoad: true,
        onlinecheckLoadSuccess: false,
      };
    case cartActionTypes.DISPLAYRAZORPAY_SUCCESS:
      return {
        ...state,
        onlinecheckLoad: false,
        onlinecheckLoadSuccess: true,
        Purchasesuccess:true,
      };
    case cartActionTypes.DISPLAYRAZORPAY_FAILURE:
      return {
        ...state,
        onlinecheckLoad: false,
        onlinecheckLoadSuccess: false,
      };
    case cartActionTypes.MAKEPAYMENT_REQUEST:
      return {
        ...state,
        paymentLoad: true,
      };
    case cartActionTypes.MAKEPAYMENT_SUCCESS:
      console.log(action.payload, "payloaddatadvalue");
      return {
        ...state,
        paymentLoad: false,
        paymentSuccess: action.payload?.data?.message,
      };
    case cartActionTypes.MAKEPAYMENT_FAILURE:
      return {
        ...state,
        paymentLoad: false,
        error: action.payload,
      };
    case cartActionTypes.STATE_RESET_REQUEST:
      return {
        ...state,
        addCardLoad: false,
        succesMessage: "",
        cartloading: false,
        getCarts: [],
        checkOutLoad: false,
        ordergetLoad: false,
        orderDetailData: [],
        onlinecheckLoad:false,
        onlinecheckLoadSuccess:false,
        paymentLoad:false,
        paymentSuccess: null,
        error: null,
        removeCartLoading:false,
        removeCartsuccess:false,
        destroyCartLoading:false,
        destroyCartsuccess:false,
        Purchasesuccess:false,
        AuthExpried:"",
      };

    default:
      return state;
  }
};

export { reducer as cartReducer };
