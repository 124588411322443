import { Col, Container, Row } from "react-bootstrap";
import Footer from "../Footer";
import Header from "../Header";
import Sidebar from "../Sidebar";
import chat from "../../assets/imgs/page/contact/chat.svg";
import call from "../../assets/imgs/page/contact/call.svg";
import map from "../../assets/imgs/page/contact/map.svg";
import delivery from "../../assets/imgs/template/delivery.svg";
import support from "../../assets/imgs/template/support.svg";
import voucher from "../../assets/imgs/template/voucher.svg";
import returned from "../../assets/imgs/template/return.svg";
import secure from "../../assets/imgs/template/secure.svg";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { logout, postContactUsRequest } from "../../store/user/user.action";
import { userSelector } from "../../store/user/user.model";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { contactUsValidationSchema } from "../../validators/Validators";

function Contact(){
  const dispatch = useDispatch();
  const { contactSuccess } = useSelector(userSelector);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(contactUsValidationSchema),
  });
  const submitHanlder = (formData) => {
    console.log(formData);
    dispatch(postContactUsRequest(formData));
  };

  useEffect(() => {
    if (contactSuccess) {
      toast.success("Received your message, We'll contact you shortly.");
      reset()
      dispatch(logout())
    }
  }, [contactSuccess]);
    return(
        <>
            <Header/>
            <Sidebar/>
      <div class="section-box">
        <div class="breadcrumbs-div mb-0">
          <Container>
          <ul className="breadcrumb">
              <li>
                <Link to="/" className="font-xs color-gray-1000">
                  <span>Home</span>
                </Link>
              </li>
              
              <li>
                <Link to="/contact" className="font-xs color-gray-500">
                  Contact Us
                </Link>
              </li>
            </ul>
          </Container>
        </div>
      </div>
      <section class="section-box shop-template mt-0">
        <Container>
          <div class="box-contact">
            <Row>
              <Col lg={6}>
              
                <div class="contact-form">
                  <h3 class="color-brand-3 mt-60">Contact Us</h3>
                  <p class="font-sm color-gray-700 mb-30">Our team would love to hear from you!</p>
                  <form onSubmit={handleSubmit(submitHanlder)}>
                  <Row>
                      <div class="left-form">
                    <Col lg={12}>
                      <div class="form-group">
                        <input class="form-control" type="text" placeholder="Name"     {...register("name")}/>
                        {errors?.name && (
                          <p className="text-danger">{errors?.name?.message}</p>
                        )}
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div class="form-group">
                        <input class="form-control" type="email" placeholder="Email"   {...register("email")}/>
                        {errors?.email && (
                          <p className="text-danger">{errors?.email?.message}</p>
                        )}
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div class="form-group">
                        <input class="form-control" type="tel" placeholder="Phone number"   {...register("phone_number")}/>
                        {errors?.phone_number && (
                          <p className="text-danger">{errors?.phone_number?.message}</p>
                        )}
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div class="form-group">
                        
                        <textarea class="form-control" placeholder="Message" rows="5" {...register("message")}></textarea>
                      </div>
                     
                    </Col>
                    <Col lg={12}>
                      <div class="form-group">
                        <input class="btn btn-buy w-auto" type="submit" />
                      </div>
                    </Col>
                    </div>
                  </Row>
                  </form>
                </div>
              </Col>
              <Col lg={6}>
               
       
              <div class="right-form">
                <p>
                  <b>Have any queries?</b>
                  <br />
                  We're here to help. Your connection to solutions and
                  assistance, available at your convenience.
                </p>
                <div class="hr"></div>
                <ul>
                  <li>
                    <i className="lni lni-map-marker"></i>
                    <span style={{ display: "flex" }}>
                      {" "}
                      87/1, Mahalakshmi Nagar, Lakshmipuram, Masakkalipalayam,
                      Coimbatore, Tamil Nadu 641028.
                    </span>
                  </li>
                  <li>
                    <i class="lni lni-phone"> </i>
                    <span>(+91) 83000 51009</span>
                  </li>
                  <li>
                    <i class="lni lni-envelope"></i>
                    <span>info@sikanpvcpipesbend.com</span>
                  </li>
                </ul>
                <iframe
                  className="map-dig"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d571.1723101790773!2d77.02003937125527!3d11.011556640307676!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba8578db7a079dd%3A0xc438876634df7ec0!2sSIKAN%20PVC%20Pipes%20%26%20Bend!5e0!3m2!1sen!2sin!4v1733141155785!5m2!1sen!2sin"
                  width="600"
                  height="450"
                  style={{ border: 0 }}
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
              {/* </div> */}
              </Col>
            </Row>
          </div>
          <div class="box-contact-address pt-80 pb-50">
            <Row>
              <Col lg={3} class="mb-30">
                <h3 class="mb-5">Visit our stores</h3>
                <p class="font-sm color-gray-700 mb-30">Find us at these locations</p><a class="btn btn-buy w-auto">View map</a>
              </Col>
              <Col lg={3}>
                <div class="mb-30">
                  <h4>Melbourne</h4>
                  <p class="font-sm color-gray-700">205 North Michigan Avenue, Suite 810<br/>Chicago, 60601, USA</p>
                </div>
                <div class="mb-30">
                  <h4>San Francisco</h4>
                  <p class="font-sm color-gray-700">205 North Michigan Avenue, Suite 810<br/>Chicago, 60601, USA</p>
                </div>
                <div class="mb-30">
                  <h4>Byron Bay</h4>
                  <p class="font-sm color-gray-700">205 North Michigan Avenue, Suite 810<br/>Chicago, 60601, USA</p>
                </div>
              </Col>
              <Col lg={3}>
                <div class="mb-30">
                  <h4>Sydney</h4>
                  <p class="font-sm color-gray-700">205 North Michigan Avenue, Suite 810<br/>Chicago, 60601, USA</p>
                </div>
                <div class="mb-30">
                  <h4>Sweden</h4>
                  <p class="font-sm color-gray-700">205 North Michigan Avenue, Suite 810<br/>Chicago, 60601, USA</p>
                </div>
                <div class="mb-30">
                  <h4>Ha Noi</h4>
                  <p class="font-sm color-gray-700">205 North Michigan Avenue, Suite 810<br/>Chicago, 60601, USA</p>
                </div>
              </Col>
              <Col lg={3}>
                <div class="mb-30">
                  <h4>Bangkok</h4>
                  <p class="font-sm color-gray-700">205 North Michigan Avenue, Suite 810<br/>Chicago, 60601, USA</p>
                </div>
                <div class="mb-30">
                  <h4>Seoul</h4>
                  <p class="font-sm color-gray-700">205 North Michigan Avenue, Suite 810<br/>Chicago, 60601, USA</p>
                </div>
                <div class="mb-30">
                  <h4>Paris</h4>
                  <p class="font-sm color-gray-700">205 North Michigan Avenue, Suite 810<br/>Chicago, 60601, USA</p>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <div class="box-contact-support pt-80 pb-50 background-gray-50">
          <Container>
            <Row>
              <Col lg={3} class="mb-30 text-center text-lg-start">
                <h3 class="mb-5">We‘d love to here from you</h3>
                <p class="font-sm color-gray-700">Chat with our friendly team</p>
              </Col>
              <Col lg={3} class="text-center mb-30">
                <div class="box-image mb-20"><img src={chat} alt="Ecom"/></div>
                <h4 class="mb-5">Chat to sales</h4>
                <p class="font-sm color-gray-700 mb-5">Speak to our team.</p><a class="font-sm color-gray-900" href="mailto:sales@ecom.com">sales@ecom.com</a>
              </Col>
              <Col lg={3} class="text-center mb-30">
                <div class="box-image mb-20"><img src={call} alt="Ecom"/></div>
                <h4 class="mb-5">Call us</h4>
                <p class="font-sm color-gray-700 mb-5">Mon-Fri from 8am to 5pm</p><a class="font-sm color-gray-900" href="tel:+1(555)000-0000">+1(555)000-0000</a>
              </Col>
              <Col lg={3} class="text-center mb-30">
                <div class="box-image mb-20"><img src={map} alt="Ecom"/></div>
                <h4 class="mb-5">Visit us</h4>
                <p class="font-sm color-gray-700 mb-5">Visit our office</p><span class="font-sm color-gray-900">205 North Michigan Avenue, Suite 810<br/>Chicago, 60601, USA</span>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      <section class="section-box mt-90 mb-50">
        <Container>
          <ul class="list-col-5">
            <li>
              <div class="item-list">
                <div class="icon-left"><img src={delivery} alt="Ecom"/></div>
                <div class="info-right">
                  <h5 class="font-lg-bold color-gray-100">Free Delivery</h5>
                  <p class="font-sm color-gray-500">From all orders over $10</p>
                </div>
              </div>
            </li>
            <li>
              <div class="item-list">
                <div class="icon-left"><img src={support} alt="Ecom"/></div>
                <div class="info-right">
                  <h5 class="font-lg-bold color-gray-100">Support 24/7</h5>
                  <p class="font-sm color-gray-500">Shop with an expert</p>
                </div>
              </div>
            </li>
            <li>
              <div class="item-list">
                <div class="icon-left"><img src={voucher} alt="Ecom"/></div>
                <div class="info-right">
                  <h5 class="font-lg-bold color-gray-100">Gift voucher</h5>
                  <p class="font-sm color-gray-500">Refer a friend</p>
                </div>
              </div>
            </li>
            <li>
              <div class="item-list">
                <div class="icon-left"><img src={returned} alt="Ecom"/></div>
                <div class="info-right">
                  <h5 class="font-lg-bold color-gray-100">Return &amp; Refund</h5>
                  <p class="font-sm color-gray-500">Free return over $200</p>
                </div>
              </div>
            </li>
            <li>
              <div class="item-list">
                <div class="icon-left"><img src={secure} alt="Ecom"/></div>
                <div class="info-right">
                  <h5 class="font-lg-bold color-gray-100">Secure payment</h5>
                  <p class="font-sm color-gray-500">100% Protected</p>
                </div>
              </div>
            </li>
          </ul>
        </Container>
      </section>
      <section class="section-box box-newsletter">
        <Container>
          <Row>
            <Col lg={6} md={7} sm={12}>
              <h3 class="color-white">Subscrible &amp; Get <span class="color-warning">10%</span> Discount</h3>
              <p class="font-lg color-white">Get E-mail updates about our latest shop and <span class="font-lg-bold">special offers.</span></p>
            </Col>
            <Col lg={4} md={5} sm={12}>
              <div class="box-form-newsletter mt-15">
                <form class="form-newsletter">
                  <input class="input-newsletter font-xs" value="" placeholder="Your email Address"/>
                  <button class="btn btn-brand-2">Sign Up</button>
                </form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
            <Footer/>
        </>
    );
}
export default Contact;