import { secretKey } from "../../services/config";
import EncryptDecrypt from "../../utils/encrypt&decrypt";
import { RiseTockenActionTypes, RiseTockenInitialState } from "./rise.model";

const reducer = (state = RiseTockenInitialState, action) => {
  switch (action.type) {
    case RiseTockenActionTypes.RISETOCKEN_GET_REQUEST:
      return {
        ...state,
        getLoading: false,
      };
    case RiseTockenActionTypes.RISETOCKEN_GET_SUCCESS:
      let payloadData = JSON.parse(
        EncryptDecrypt.decryptdata(action?.payload.data?.datas, secretKey)
      );
      return {
        ...state,
        getLoading: false,
        tocken: payloadData,
      };
    case RiseTockenActionTypes.RISETOCKEN_GET_FAILURE:
      return {
        ...state,
        getLoading: false,
      };

    case RiseTockenActionTypes.RISETOCKEN_POST_REQUEST:
      return {
        ...state,
        postLoading: true,
        postSuccess: false,
      };
    case RiseTockenActionTypes.RISETOCKEN_POST_SUCCESS:
      return {
        ...state,
        postLoading: false,
        postSuccess: true,
      };
    case RiseTockenActionTypes.RISETOCKEN_POST_FAILURE:
      return {
        ...state,
        postLoading: false,
        postSuccess: false,
      };
      case RiseTockenActionTypes.RESTSET_STATE:
      return {
        ...state,
        postSuccess: false,
      };

    default:
      return state;
  }
};
export { reducer as RiseTockenReducer };
