import { action } from "typesafe-actions";
import { UserActionTypes } from "./user.model";

export const userRegisterRequest = (req) =>
  action(UserActionTypes.REGISTER_USER_REQUEST, req);
export const userRegisterSuccess = (res) =>
  action(UserActionTypes.REGISTER_USER_SUCCESS, res);
export const userRegisterFailure = (err) =>
  action(UserActionTypes.REGISTER_USER_FAILURE, err);

export const userLoginRequest = (req) =>
  action(UserActionTypes.LOGIN_USER_REQUEST, req);
export const userLoginSuccess = (res) =>
  action(UserActionTypes.LOGIN_USER_SUCCESS, res);
export const userLoginFailure = (err) =>
  action(UserActionTypes.LOGIN_USER_FAILURE, err);

export const logout = () => action(UserActionTypes.USER_LOGOUT);
export const resetUserDataRequest = (req) =>
  action(UserActionTypes.USERDETAIL_RESET, req);
export const loginStateReset = () => action(UserActionTypes.LOGINSUCCESS_RESET);

export const getUserDataRequest = (req) =>
  action(UserActionTypes.GETUSERDATA_REQUEST, req);
export const getUserDataSuccess = (res) =>
  action(UserActionTypes.GETUSERDATA_SUCCESS, res);
export const getUserDataFailure = (err) =>
  action(UserActionTypes.GETUSERDATA_FAILURE, err);

export const updateUserRequest = (req) =>
  action(UserActionTypes.UPDATE_USER_REQUEST, req);
export const updateUserSuccess = (res) =>
  action(UserActionTypes.UPDATE_USER_SUCCESS, res);
export const updateUserFailure = (err) =>
  action(UserActionTypes.UPDATE_USER_FAILURE, err);

export const postContactUsRequest = (req) =>
  action(UserActionTypes.POSTCONTACT_US_REQUEST, req);
export const postContactUsSuccess = (res) =>
  action(UserActionTypes.POSTCONTACT_US_SUCCESS, res);
export const postContactUsFailure = (err) =>
  action(UserActionTypes.POSTCONTACT_US_FAILURE, err);
