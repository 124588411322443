import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useNavigate,
} from "react-router-dom";

import logo from "../../src/assets/imgs/sikan-logo.png";
import avatar from "../../src/assets/imgs/avatar-icon-images.jpg";
import en from "../../src/assets/imgs/template/en.svg";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import flagen from "../../src/assets/imgs/template/flag-en.svg";
import flages from "../../src/assets/imgs/template/flag-es.svg";
import flagpt from "../../src/assets/imgs/template/flag-pt.svg";
import flagcn from "../../src/assets/imgs/template/flag-cn.svg";
import flagfr from "../../src/assets/imgs/template/flag-fr.svg";
import { useDispatch, useSelector } from "react-redux";
import { cartGetRequest } from "../store/cart/cart.action";
import { cartSelector } from "../store/cart/cart.model";
import { productSelector } from "../store/products/products.model";
import { createProductPayload } from "../utils/utils";
import { filterProductGetRequest } from "../store/products/products.action";
import { getUserDataRequest } from "../store/user/user.action";
import alertmessage from "../utils/alert";

function Header() {
  const isAuth = localStorage.getItem("isAuthenticated");
  const authKey = localStorage.getItem("authKey");
  const role = localStorage.getItem("role");
  const id = localStorage.getItem("id");

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { getCarts,AuthExpried } = useSelector(cartSelector);
  const { filterProductData } = useSelector(productSelector);

  const [searchQuery, setSearchQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State to manage mobile menu open/close

  // Toggle mobile menu
  const toggleMobileMenu = () => {
    setIsMenuOpen(!isMenuOpen);

    // Toggle class on the body element
    if (isMenuOpen) {
      document.body.classList.remove("mobile-menu-active");
    } else {
      document.body.classList.add("mobile-menu-active");
    }
  };

  /* Function */
  const handleSuggestionClick = (suggestion) => {
    setSearchQuery(suggestion.name);
    setSuggestions([]);
    navigate(`/purchase?query=${encodeURIComponent(suggestion.name)}`);
  };

  const handleSearchInputChange = (event) => {
    const input = event.target.value;
    setSearchQuery(input);

    if (input.length > 3) {
      const payload = createProductPayload("", "", input, "");
      dispatch(filterProductGetRequest(payload));
      setSuggestions(filterProductData);
    } else {
      setSuggestions([]);
    }
  };

  const handleClick = () => {
    const payload = { userId: id };
    dispatch(getUserDataRequest(payload));
  };
  // useEffect
  // useEffect(()=>{
  //   const payload = {
  //     page: 0,
  //     limit: 0,
  //     query: "",
  //   };
  //   dispatch(cartGetRequest(payload));
  // },[])
   useEffect(() => {
    if(isAuth){
      const payload = {
        page: 0,
        limit: 0,
        query: "",
      };
      dispatch(cartGetRequest(payload));
    }
    else{
      // navigate('/login')
      // window.location.reload(); 
    }
    // const payload = {
    //   page: 0,
    //   limit: 0,
    //   query: "",
    // };
    // dispatch(cartGetRequest(payload));
  }, [isAuth]);
//   useEffect(()=>{
// if(AuthExpried==="Your session expired! Please login to continue!"){
//   alertmessage.sweetalert(AuthExpried)
// }
//   },[AuthExpried])
  return (
    <>
      <header className="header sticky-bar">
        <div className="container">
          <div className="main-header">
            <div className="header-left">
              <div className="header-logo">
                <Link to="/" className="active">
                  <a className="d-flex">
                    <img alt="Ecom" src={logo} />
                  </a>
                </Link>
              </div>
              <div className="header-search">
                <div className="box-header-search ml-30">
                  <form className="form-search">
                    <div className="box-keysearch">
                      <input
                        className="form-control font-xs"
                        type="text"
                        value={searchQuery}
                        placeholder="Search for items"
                        onChange={handleSearchInputChange}
                      />
                    </div>
                  </form>
                  <div className="suggestions">
                    {suggestions?.length > 0 && (
                      <ul className="suggestion-list">
                        {suggestions?.map((suggestion, index) => (
                          <li
                            key={index}
                            onClick={() => handleSuggestionClick(suggestion)}
                          >
                            {suggestion.name}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
              </div>
              <div className="header-nav">
                <nav className="nav-main-menu d-none d-xl-block">
                  <ul className="main-menu">
                    <li>
                      <Link to="/" className="active">
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link to="/purchase">Purchase</Link>
                    </li>
                    <li>
                      <Link to="/about">About </Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact Us</Link>
                    </li>
                    {isAuth ? (
                      <li>
                        <a>My account</a>
                        <ul className="top-50">
                          <Link to="/profile" onClick={handleClick}>
                            <li className="active">Profile</li>
                          </Link>
                          <Link to="/orders">
                            <li className="active">Orders</li>
                          </Link>
                          <Link to="/risetocken">
                            <li className="active">Support</li>
                          </Link>
                          <Link
                            to="/"
                            onClick={(e) => {
                              localStorage.clear();
                              navigate("/");
                            }}
                          >
                            <li className="active">Logout</li>
                          </Link>
                        </ul>
                      </li>
                    ) : (
                      ""
                    )}
                  </ul>
                </nav>
                <div
                  className="burger-icon burger-icon-white"
                  onClick={toggleMobileMenu}
                >
                  <span className="burger-icon-top"></span>
                  <span className="burger-icon-mid"></span>
                  <span className="burger-icon-bottom"></span>
                </div>
              </div>
              <div className="header-shop">
                <div className="d-inline-block box-dropdown-cart">
                  {isAuth ? (
                    <Link to="/shopcart" className="active">
                      <span className="font-lg icon-list icon-cart">
                        <span>Cart</span>
                        <span className="number-item font-xs">
                          {getCarts?.length}
                        </span>
                      </span>
                    </Link>
                  ) : (
                    <Link to="/login" className="active">
                      <span>Login</span>
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* Mobile Menu */}
      <div
        className={`mobile-header-active mobile-header-wrapper-style perfect-scrollbar ${
          isMenuOpen ? "sidebar-visible" : ""
        }`}
      >
        <div className="mobile-header-wrapper-inner">
          <div className="mobile-header-content-area">
            <div className="mobile-logo">
              <a className="d-flex" href="/">
                <img alt="Ecom" src={logo} />
              </a>
            </div>
            <div className="mobile-menu-wrap mobile-header-border">
              <nav className="mt-15">
                <ul className="mobile-menu font-heading">
                  <li className="has-children">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="has-children">
                    <Link to="/purchase">Purchase</Link>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="mobile-account">
              <div className="mobile-header-top">
                <div className="user-account">
                  <a href="/profile">
                    <img src={avatar} alt="avatar" />
                  </a>
                  <div className="content">
                    <h6 className="user-name">
                      Hello<span className="text-brand"> User!</span>
                    </h6>
                    <p className="font-xs text-muted">
                      You have 3 new messages
                    </p>
                  </div>
                </div>
              </div>
              <ul className="mobile-menu">
                <li>
                  <Link to="/profile">My Account</Link>
                </li>
                <li>
                  <Link to="/orders">My Orders</Link>
                </li>
                <li>
                  <Link to="/login">Sign out</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
