import { call, put, takeEvery } from "redux-saga/effects";
import { deleteCatergoryApi,  getCatApi, postCatApi,  updateCatergoryApi } from "./category.api";
import * as CategoryAddAction from "./category.action";
import { categoryAddActionType } from "./category.model";
import Swal from "sweetalert2";

function* handlepostData(action) {

    try {
      const response = yield call(postCatApi, action.payload);
      yield put(CategoryAddAction.postCatSuccess(response));
      console.log(response, "5trgybb");
      Swal.fire({
        title: "Success",
        text: "Catergory List Added Succesfully!",
        icon: "success",
      });
    } catch (e) {
      yield put(CategoryAddAction.postCatFailure(e));
      Swal.fire({
        title: "Error",
        text: e?.response?.data?.message,
        icon: "error",
      });
    }
  }
  
  export function* handlegetData(action) {
    try {
      const response = yield call(getCatApi, action.payload);
      yield put(CategoryAddAction.getCatSuccess(response));
      
    } catch (e) {
      yield put(CategoryAddAction.getCatFailure(e));
    }
  }
  
  
  function* handleUpdateCatergoryData(action) {
  
    try {
      const response = yield call(updateCatergoryApi, action.payload);
      yield put(CategoryAddAction.updateCatSuccess(response));
    
      Swal.fire({
        title: "Success",
        text: "Category List Updated Succesfully!",
        icon: "success",
      });
    } catch (e) {
      yield put(CategoryAddAction.updateCatFailure(e));
      Swal.fire({
        title: "Error",
        text: e?.response?.data?.message,
        icon: "error",
      });
    }
  }
  function* handleDeleteCatergoryData(action) {
  
    try {
      const response = yield call(deleteCatergoryApi, action.payload);
      yield put(CategoryAddAction.deleteCatSuccess(response));
     
      Swal.fire({
        title: "Success",
        text: "Category List Delete Succesfully!",
        icon: "success",
      });
    } catch (e) {
      yield put(CategoryAddAction.deleteCatFailure(e));
      Swal.fire({
        title: "Error",
        text: e?.response?.data?.message,
        icon: "error",
      });
    }
  }
  
export function* CategoryAddSaga() {
    yield takeEvery(categoryAddActionType.POSTCATEGORY_REQUEST, handlepostData);
    yield takeEvery(categoryAddActionType.GETCATEGORY_REQUEST, handlegetData);
    yield takeEvery(
      categoryAddActionType.UPDATECATEGORY_REQUEST,
      handleUpdateCatergoryData
    );
  
    yield takeEvery(
      categoryAddActionType.DELETECATEGORY_REQUEST,
      handleDeleteCatergoryData
    );
  
}