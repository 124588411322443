import { decryptdata } from "../../utils/encrypt&decrypt";
import { catAddInitialState, categoryAddActionType } from "./category.model";

const reducer = (state = catAddInitialState, action) => {
  switch (action.type) {
    case categoryAddActionType.POSTCATEGORY_REQUEST:
      return {
        ...state,
        postLoading: true,
        sendSuccess: false,
      };
    case categoryAddActionType.POSTCATEGORY_SUCCESS:
      return {
        ...state,
        postLoading: false,
        sendSuccess: true,
      };
    case categoryAddActionType.POSTCATEGORY_FAILURE:
      return {
        ...state,
        postLoading: false,
        sendSuccess: false,
      };
    case categoryAddActionType.GETCATEGORY_REQUEST:
      return {
        ...state,
        getLoading: true,
      };
    case categoryAddActionType.GETCATEGORY_SUCCESS:
      const { payload } = action;
      console.log(payload, process.env.SECRET_KEY, "jkmls");
      let payloaddatas = JSON.parse(
        decryptdata(
          payload?.data?.datas,
          "pubkey-b55b1e3a8d35df839ae1c9fe33ac7ad7"
        )
      );
      console.log(payloaddatas.data, "yhoio");
      return {
        ...state,
        getLoading: false,
        getCatData: payloaddatas?.data,
      };
    case categoryAddActionType.GETCATEGORY_FAILURE:
      return {
        ...state,
        getLoading: false,
      };

    case categoryAddActionType.UPDATECATEGORY_REQUEST:
      return {
        ...state,
        updateCatLoading: true,
        catUpdatedSuccess: false,
      };
    case categoryAddActionType.UPDATECATEGORY_SUCCESS:
      return {
        ...state,
        updateCatLoading: false,
        catUpdatedSuccess: true,
      };
    case categoryAddActionType.UPDATECATEGORY_FAILURE:
      return {
        ...state,
        updateCatLoading: false,
        catUpdatedSuccess: false,
      };

    case categoryAddActionType.DELETECATEGORY_REQUEST:
      return {
        ...state,
        deleteCatLoading: true,
        catDeleteSuccess: false,
      };
    case categoryAddActionType.DELETECATEGORY_SUCCESS:
      return {
        ...state,
        deleteCatLoading: false,
        catDeleteSuccess: true,
      };
    case categoryAddActionType.DELETECATEGORY_FAILURE:
      return {
        ...state,
        deleteCatLoading: false,
        catDeleteSuccess: false,
      };
      default:
        return state;
    }
  };
  export { reducer as CategoryAddReducer };
  