import Swal from 'sweetalert2';
// import { Navigate } from 'react-router-dom';


export default class alertmessage {
  
    static sweetalert(message) {
        //   const  navigate=Navigate()
        let title = "Maintenance..!";
        let text = message;
        if(message==="Your session expired! Please login to continue!"){
            title = "User Session Expired..!";
            text = "Re-Login Again";
        }
        Swal.fire({
            title: title,
            text: text,
            type: "danger"  
        }).then(function () {
            localStorage.clear();
            // navigator('/login') 
            window.location.reload(true);
        });
    }
}