import { call, put, takeEvery } from "redux-saga/effects";
import { accountActionTypes } from "./account.model";
import * as accActions from "./account.action";
import { addAccApi } from "./account.api";
export function* handleGetCarts(action) {
  console.log(action, "6789");
  try {
    const response = yield call(addAccApi, action.payload);
    yield put(accActions.accGetSuccess(response));

  } catch (e) {
    yield put(accActions.accGetFailure(e));
  }
}

export function* accSaga() {
  yield takeEvery(accountActionTypes.ACC_GET_REQUEST, handleGetCarts);
}
