import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Row } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import Footer from "../Footer";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { getUserDataRequest, logout, updateUserRequest } from "../../store/user/user.action";
import { userSelector } from "../../store/user/user.model";
import { userUpdateValidator } from "../../validators/Validators";
function Profile() {
  const dispatch = useDispatch();
 const id= localStorage.getItem("id")
console.log(dispatch, "disasdf")
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(userUpdateValidator),
  });
  /* Selectors */
  const {  userData, updateSuccess } = useSelector(userSelector);
  /* Function */
  const onSubmitHandler = (formData) => {
    delete formData.role_id;
    const payload = {
        id:userData[0]?.id,
        formData,
      };
      dispatch(updateUserRequest(payload));
  };
  useEffect(()=>{
    const payload = {
        userId:id,
      };
      dispatch(getUserDataRequest(payload));
  },[])
  /* useEfffect */
  useEffect(() => {
    if (userData?.length > 0) {
      userData?.map((ele) => {
        reset({
          email: ele?.email,
          mobile: ele?.mobile,
          name: ele?.name,
          password: ele?.password,
          role_id: ele?.role_id,
        });
      });
    }
  }, [userData]);
  useEffect(() => {
    if (updateSuccess) {
        const payload = {
            userId:id,
          };
          dispatch(getUserDataRequest(payload));
      dispatch(logout());
    }
  }, [ updateSuccess]);
  return (
    <>
      <Header />
      <Sidebar />
      <section class="section-box shop-template mt-30">
        <Container class="box-account-template">
          {/* <h3>Hello Steven</h3>
          <p class="font-md color-gray-500">
          From your account dashboard, you can conveniently manage your billing and shipping addresses,
            <br class="d-none d-lg-block" />
            edit your password, and update your account details to keep your profile up-to-date.
          </p> */}
          <div class="box-tabs mb-100">
            <h4 > Profile Setting</h4>
            <div class="border-bottom mt-20 mb-40"></div>
            <div class="tab-content mt-30">
              <Row className="justify-content-center">
                <Col lg={6} class="mb-20">
                  <form onSubmit={handleSubmit(onSubmitHandler)}>
                    <Row>
                      <Col lg={12} class="mb-20">
                        <h5 class="font-md-bold color-brand-3 text-sm-start text-center">
                          Contact information
                        </h5>
                      </Col>
                      {/* <Col lg={12}>
                        <div class="form-group">
                          <input
                            class="form-control font-sm"
                            type="text"
                            placeholder="Fullname *"
                          />
                        </div>
                      </Col> */}
                      <Col lg={12}>
                        <div class="form-group">
                          <input
                            class="form-control font-sm"
                            type="text"
                          {...register("name")}
                            placeholder="Your Name"
                          />
                        </div>
                      </Col>
                      <Col lg={12}>
                        <div class="form-group">
                          <input
                            class="form-control font-sm"
                            type="text"
                          {...register("mobile")}
                            placeholder="Your Number"
                          />
                        </div>
                      </Col>
                      <Col lg={12}>
                        <div class="form-group">
                          <input
                            class="form-control font-sm"
                            type="text"
                          {...register("email")}
                            placeholder="Your Email"
                          />
                        </div>
                      </Col>
                      <Col lg={12}>
                        <div class="form-group">
                          <input
                            class="form-control font-sm"
                            type="text"
                            {...register("password")}
                            placeholder="Change Password"
                          />
                        </div>
                      </Col>
                      {/* <Col lg={12}>
                        <div class="form-group">
                          <label
                            class="font-sm color-brand-3"
                            for="checkboxOffers"
                          >
                            <input
                              class="checkboxOffer"
                              id="checkboxOffers"
                              type="checkbox"
                            />
                            Keep me up to date on news and exclusive offers
                          </label>
                        </div>
                      </Col> */}
                      <Col lg={12}>
                        <h5 class="font-md-bold color-brand-3 mt-15 mb-20">
                          Address
                        </h5>
                      </Col>
                      {/* <Col lg={6}>
                          <div class="form-group">
                            <input
                              class="form-control font-sm"
                              type="text"
                              placeholder="First name*"
                            />
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div class="form-group">
                            <input
                              class="form-control font-sm"
                              type="text"
                              placeholder="Last name*"
                            />
                          </div>
                        </Col> */}
                      <Col lg={12}>
                        <div class="form-group">
                        <input
                          className="form-control"
                          id="role_id"
                          type="text"
                          {...register("gst_no")}
                          placeholder="Enter GST Number"
                        />
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div class="form-group">
                        <input
                          className="form-control"
                          id="city"
                          type="text"
                          {...register("city")}
                          placeholder="Enter your city"
                        />
                        </div>
                      </Col>
                     
                      <Col lg={6}>
                        <div class="form-group">
                        <input
                          className="form-control"
                          id="zipcode"
                          type="text"
                          {...register("zipcode")}
                          placeholder="Enter your zipcode"
                        />
                        </div>
                      </Col>
                   
                      {/* <Col lg={6}>
                        <div class="form-group">
                          <input
                            class="form-control font-sm"
                            type="text"
                            placeholder="Company name"
                          />
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div class="form-group">
                          <input
                            class="form-control font-sm"
                            type="text"
                            placeholder="Phone*"
                          />
                        </div>
                      </Col> */}
                      <Col lg={12}>
                        <div class="form-group mb-0">
                        <textarea
                          className="form-control"
                          id="address"
                          {...register("address")}
                          placeholder="Enter your address"
                          rows="3"
                        ></textarea>
                        </div>
                      </Col>
                      <Col lg={12}>
                        <div class="form-group mt-20">
                          <button
                            class="btn btn-buy w-auto h54 font-md-bold"
                            type="submit"
                          >Submit</button>
                        </div>
                      </Col>
                    </Row>
                  </form>
                </Col>
             
              </Row>
            </div>
          </div>
        </Container>
      </section>
      <section class="section-box box-newsletter">
        <Container>
          <Row>
            <Col lg={6}>
              <h3 class="color-white">
                Subscrible &amp; Get <span class="color-warning">10%</span>{" "}
                Discount
              </h3>
              <p class="font-lg color-white">
                Get E-mail updates about our latest shop and{" "}
                <span class="font-lg-bold">special offers.</span>
              </p>
            </Col>
            <Col lg={4} md={5} sm={12}>
              <div class="box-form-newsletter mt-15">
                <form class="form-newsletter">
                  <input
                    class="input-newsletter font-xs"
                    value=""
                    placeholder="Your email Address"
                  />
                  <button class="btn btn-brand-2">Sign Up</button>
                </form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </>
  );
}
export default Profile;
